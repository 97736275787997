import { Box } from 'components/Box'
import { ReactComponent as PhoneIncomingCallIcon } from 'assets/icons/phone-incoming-call-icon.svg'
import { ReactComponent as BuildingIcon } from 'assets/icons/building-icon.svg'
import { ReactComponent as PhoneIcon } from 'assets/icons/phone-icon.svg'

import { useIncomingCallDetailsStyles } from './styles'

interface IncomingCallDetailsProps {
  incomingPhoneNumber: string
  contactPhoneNumber: string
  companyName: string
  agentPhone?: string
  team?: string
}

export const IncomingCallDetails: React.FC<IncomingCallDetailsProps> = ({
  incomingPhoneNumber,
  contactPhoneNumber,
  companyName,
  agentPhone,
  team,
}) => {
  const classes = useIncomingCallDetailsStyles()

  return (
    <div className={classes.container}>
      <Box className={classes.section}>
        <div className={classes.element}>
          <PhoneIncomingCallIcon />
          <div className={classes.elementInfo}>
            <strong>{incomingPhoneNumber}</strong>
            <span>Номер з якого телефонують</span>
          </div>
        </div>
      </Box>

      <Box className={classes.section}>
        <div className={classes.element}>
          <BuildingIcon />
          <div className={classes.elementInfo}>
            <strong>{companyName}</strong>
            <span>Компанія</span>
          </div>
        </div>

        <div className={classes.element}>
          <PhoneIcon />
          <div className={classes.elementInfo}>
            <strong>{contactPhoneNumber}</strong>
            <span>Контакт-центр</span>
          </div>
        </div>
      </Box>

      {(agentPhone || team) && (
        <Box className={classes.section}>
          <div className={classes.element}>
            <PhoneIcon />
            <div className={classes.elementInfo}>
              <strong>{agentPhone || '-'}</strong>
              <span>Агент</span>
            </div>
          </div>

          <div className={classes.element}>
            <BuildingIcon />
            <div className={classes.elementInfo}>
              <strong>{team || '-'}</strong>
              <span>Команда</span>
            </div>
          </div>
        </Box>
      )}
    </div>
  )
}
