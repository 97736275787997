import React from 'react'

import { Pagination } from 'components/Pagination'
import { PersonalAccountListItem } from 'components/PersonalAccountListItem'
import { type PersonalAccountsListItem, ContactListItem } from 'store/personal-accounts'

import { usePersonalAccountListStyles } from './styles'

interface PersonalAccountListProps {
  page: number
  onPageChange: (page: number) => void
  pageCount: number
  data: Array<PersonalAccountsListItem | ContactListItem>
  showCreateRequestButton?: boolean
  createRequestLinkQueryParams?: Record<string, string>
}

export const PersonalAccountList: React.FC<PersonalAccountListProps> = ({
  data,
  onPageChange,
  page,
  pageCount,
  showCreateRequestButton = true,
  createRequestLinkQueryParams,
}) => {
  const classes = usePersonalAccountListStyles()

  if (!data.length) {
    return null
  }

  return (
    <div>
      <div className={classes.header}>
        <div>Тип</div>
        <div>О/Р</div>
        <div>Власник та адреса О/Р</div>
        <div>Звернення</div>
        <div>Останнє звер.</div>
      </div>
      <div>
        {data.map((item) => (
          <PersonalAccountListItem
            key={`${item.identifierType}-${item.id}`}
            item={item}
            showCreateRequestButton={showCreateRequestButton}
            createRequestLinkQueryParams={createRequestLinkQueryParams}
          />
        ))}
      </div>

      <Pagination page={page} pageCount={pageCount} onPageChange={({ selected }) => onPageChange(selected)} />
    </div>
  )
}
