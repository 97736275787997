import type { ContactFormState } from 'components/ContactForm'
import type { CreateLegalEntityContactRequestDto, CreateNaturalPersonContactRequestDto } from 'store/contacts'

export const mapLegalEntityContactToSubmit = (
  formState: ContactFormState
): Omit<CreateLegalEntityContactRequestDto, 'id'> => {
  return {
    comment: formState.comment!,
    company: formState.company,
    ...(formState.phoneNumber && {
      phoneNumber: +formState.phoneNumber!,
    }),
    companyRepresentativeFirstName: formState.firstName!,
    companyRepresentativeLastName: formState.lastName!,
    companyRepresentativeMiddleName: formState.middleName!,
    companyName: formState.companyName!,
  }
}

export const mapNaturalPersonContactToSubmit = (
  formState: ContactFormState
): Omit<CreateNaturalPersonContactRequestDto, 'id'> => {
  return {
    comment: formState.comment!,
    company: formState.company,
    ...(formState.phoneNumber && {
      phoneNumber: +formState.phoneNumber!,
    }),
    firstName: formState.firstName!,
    lastName: formState.lastName!,
    middleName: formState.middleName!,
  }
}
