import { RequestType, Option } from 'types'

const requestTypeLabel: Record<RequestType, string> = {
  [RequestType.INCOMING_CALL]: 'Вхідний дзвінок',
  [RequestType.OUTGOING_CALL]: 'Вихідний дзвінок',
  [RequestType.TELEGRAM]: 'Телеграм',
  [RequestType.VIBER]: 'Вайбер',
  [RequestType.CHAT_WIDGET]: 'Чат-віджет',
}

export const getRequestTypeLabel = (requestType?: RequestType): string => {
  if (!requestType) return ''
  return requestTypeLabel[requestType]
}

export const requestTypeList = [
  RequestType.INCOMING_CALL,
  RequestType.OUTGOING_CALL,
  RequestType.TELEGRAM,
  RequestType.VIBER,
  RequestType.CHAT_WIDGET,
] as const

export const requestTypeOptions: Option<RequestType>[] = requestTypeList.map((key) => ({
  key,
  text: getRequestTypeLabel(key),
}))
