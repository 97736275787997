import { api } from '../api'
import type { GetReportsByDataDto } from './dtos/GetReportsByData'
import type { GetReportsByB2bRequestDto, GetReportsByB2bResponseDto } from './dtos/GetReportsByB2b'
import type { GetReportsByThemeRequestDto, GetReportsByThemeResponseDto } from './dtos/GetReportsByTheme'
import type { GetReportsByKeywordsRequestDto, GetReportsByKeywordsResponseDto } from './dtos/GetReportsByKeywords'
import axios from 'libs/axios'

const downloadReportHeaders = {
  Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

const defaultParams = {
  limit: 1000,
  offset: 0,
}

const downloadFile = (file: Blob, fileName = 'file') => {
  const blob = new Blob([file])
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

const reportsEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    getReportsByData: builder.query<GetReportsByDataDto, void>({
      query: () => ({
        url: '/reports',
        method: 'GET',
      }),
    }),
    downloadReportsByData: builder.mutation<unknown, void>({
      queryFn: async () => {
        await axios({
          url: '/reports',
          headers: downloadReportHeaders,
          responseType: 'blob',
        })
          .then((response) => downloadFile(response.data, 'report.xlsx'))
          .catch(() => console.error('Something went wrong. Please try again later.'))

        return { data: undefined }
      },
    }),
    getReportsByB2b: builder.query<GetReportsByB2bResponseDto, GetReportsByB2bRequestDto>({
      query: (params) => ({
        url: '/reports/by-b2b-segment',
        params: { ...params, ...defaultParams },
        method: 'GET',
      }),
    }),
    downloadReportsByB2b: builder.mutation<unknown, GetReportsByB2bRequestDto>({
      queryFn: async (params) => {
        await axios({
          url: '/reports/by-b2b-segment',
          params: { ...params, ...defaultParams },
          headers: downloadReportHeaders,
          responseType: 'blob',
        })
          .then((response) => downloadFile(response.data, 'report-by-b2b.xlsx'))
          .catch(() => console.error('Something went wrong. Please try again later.'))

        return { data: undefined }
      },
    }),
    getReportsByKeywords: builder.query<GetReportsByKeywordsResponseDto, GetReportsByKeywordsRequestDto>({
      query: (params) => ({
        url: '/reports/by-keywords',
        params: { ...params, ...defaultParams },
        method: 'GET',
      }),
    }),
    downloadReportsByKeywords: builder.mutation<unknown, GetReportsByKeywordsRequestDto>({
      queryFn: async (params) => {
        await axios({
          url: '/reports/by-keywords',
          params: { ...params, ...defaultParams },
          headers: downloadReportHeaders,
          responseType: 'blob',
        })
          .then((response) => downloadFile(response.data, 'report-by-keywords.xlsx'))
          .catch(() => console.error('Something went wrong. Please try again later.'))

        return { data: undefined }
      },
    }),
    getReportsByTheme: builder.query<GetReportsByThemeResponseDto, GetReportsByThemeRequestDto>({
      query: (params) => ({
        url: '/reports/by-theme',
        params: { ...params, ...defaultParams },
        method: 'GET',
      }),
    }),
    downloadReportsByTheme: builder.mutation<unknown, GetReportsByThemeRequestDto>({
      queryFn: async (params) => {
        await axios({
          url: '/reports/by-theme',
          params: { ...params, ...defaultParams },
          headers: downloadReportHeaders,
          responseType: 'blob',
        })
          .then((response) => downloadFile(response.data, 'report-by-theme.xlsx'))
          .catch(() => console.error('Something went wrong. Please try again later.'))

        return { data: undefined }
      },
    }),
  }),
})

export const {
  useLazyGetReportsByDataQuery,
  useLazyGetReportsByB2bQuery,
  useLazyGetReportsByThemeQuery,
  useLazyGetReportsByKeywordsQuery,
  useDownloadReportsByB2bMutation,
  useDownloadReportsByDataMutation,
  useDownloadReportsByKeywordsMutation,
  useDownloadReportsByThemeMutation,
} = reportsEndpoints
