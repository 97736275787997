import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import clsx from 'clsx'

import { Helmet } from 'components/Helmet'
import { MeterType, PersonType, SupplyType } from 'types'
import { PageWrapper } from 'components/PageWrapper'
import { RequestForm, RequestFormState } from 'components/RequestForm'
import { RequestDetails } from 'components/RequestDetails'
import { NewReadingModal, NewReadingFormState } from 'components/NewReadingModal'
import { PersonalAccountDetails } from 'components/PersonalAccountDetails'
import { PersonalAccountHistory } from 'components/PersonalAccountListItem/PersonalAccountHistory'
import { Account, PersonalAccountConnectedAccounts } from 'components/PersonalAccountConnectedPhones'
import { PersonalAccountFinance } from 'components/PersonalAccountFinance'
import { PersonalAccountMeterReadings } from 'components/PersonalAccountMeterReadings'
import {
  useCreatePersonalAccountMeterReadingMutation,
  useGetPersonalAccountBalanceQuery,
  useGetPersonalAccountConnectedPhonesQuery,
  useGetPersonalAccountMeterReadingQuery,
  useGetPersonalAccountQuery,
  useUpdatePersonalAccountConnectedPhoneMutation,
  useCreatePersonalAccountConnectedPhoneMutation,
} from 'store/personal-accounts'
import { useCreateRequestMutation, useUpdateRequestMutation } from 'store/requests'
import { Skeleton } from 'components/Skeleton'
import { useRequest } from 'hooks/useRequest'
import { AccountDetailsModal, AccountDetailsFormState } from 'components/AccountDetailsModal'
import addRequestIcon from 'assets/icons/add-request-icon.svg'
import editRequestIcon from 'assets/icons/edit-request-icon.svg'
import { useAccount } from 'hooks/useAccount'
import { mapAccountToEdit } from 'utils/mappers/mapAccountToEdit'
import { mapRequestToEdit } from 'utils/mappers/mapRequestToEdit'
import { mapAccountToSubmit } from 'utils/mappers/mapRequestToSubmit'
import { mapAccountToSubmitUpdate, mapAccountToSubmitCreate } from 'utils/mappers/mapAccountToSubmit'
import { mapPhoneToBackend } from 'utils/phone'
import { useGetCompaniesDictionaryQuery } from 'store/dictionaries'
import { useGoBackOrClose } from 'hooks/useGoBackOrClose'
import { useBeforeUnload } from 'hooks/useBeforeUnload'

import { usePersonalAccountRequestPageStyles } from './styles'

export const PersonalAccountRequestPage = () => {
  const goBackOrClose = useGoBackOrClose()
  const classes = usePersonalAccountRequestPageStyles()
  const { isSupervisorInCompany, isInCompany } = useAccount()

  const { id, requestId } = useParams<{ id: string; requestId?: string }>()
  const [searchParams] = useSearchParams()
  const phone = searchParams.get('ani')!
  const company = searchParams.get('company')
  const contactCenterPhone = searchParams.get('dn')!
  const agentPhoneNumber = searchParams.get('ext')!
  const team = searchParams.get('team')!
  const callId = searchParams.get('callid')!

  // --- state ---
  const [newReadingModalVisible, toggleNewReadingModalVisible] = React.useReducer((prev) => !prev, false)
  const [accountToEdit, setAccountToEdit] = React.useState<Account | null>(null)
  const [accountDetailsModalVisible, toggleAccountDetailsModalVisible] = React.useReducer((prev) => !prev, false)

  // --- queries ---
  const { data: request } = useRequest()
  const { data: balance } = useGetPersonalAccountBalanceQuery(id!)
  const { data: personalAccount } = useGetPersonalAccountQuery(id!)
  const { data: connectedPhones = [] } = useGetPersonalAccountConnectedPhonesQuery(id!)
  const { data: meterReading } = useGetPersonalAccountMeterReadingQuery(id!)
  const { data: companiesDictionary } = useGetCompaniesDictionaryQuery()
  const internalCompany = companiesDictionary?.find((item) => item.ciscoId === company)?.id

  // --- mutations ---
  const [createRequest, createRequestParams] = useCreateRequestMutation()
  const [updateRequest, updateRequestParams] = useUpdateRequestMutation()
  const [createPersonalAccountMeterReading] = useCreatePersonalAccountMeterReadingMutation()
  const [updatePersonalAccountConnectedPhone] = useUpdatePersonalAccountConnectedPhoneMutation()
  const [createPersonalAccountConnectedPhone] = useCreatePersonalAccountConnectedPhoneMutation()

  // --- side effects ---
  const submitted = [createRequestParams.isSuccess, updateRequestParams.isSuccess].some(Boolean)
  useBeforeUnload('Ви впевнені, що хочете залишити цю сторінку? Всі незбережені дані будуть втрачені.', !submitted)

  // --- handlers ---
  const handleNewReadingModalSubmit = async (formState: NewReadingFormState) => {
    await createPersonalAccountMeterReading({ id: id!, ...formState })
    toggleNewReadingModalVisible()
  }

  const handleSubmitRequestForm = async (formState: RequestFormState) => {
    try {
      const dto = {
        ...mapAccountToSubmit(formState),
        personalAccountId: +id!,
      }

      if (requestId) {
        await updateRequest({
          ...dto,
          id: +requestId!,
        }).unwrap()
      } else {
        await createRequest({
          ...dto,
          number: request!.number,
          company: internalCompany!,
          incomePhoneNumber: mapPhoneToBackend(phone),
          contactCenterPhoneNumber: mapPhoneToBackend(contactCenterPhone),
          ...(callId && { callId }),
          ...(team && { team }),
          ...(agentPhoneNumber && { agentPhoneNumber: parseInt(agentPhoneNumber) }),
        }).unwrap()
      }

      goBackOrClose()
    } catch {
      alert('Помилка при збереженні')
    }
  }

  const handlePersonalAccountConnectedAccountsEdit = (account: Account) => {
    setAccountToEdit(account)
    toggleAccountDetailsModalVisible()
  }

  const handleAccountDetailsModalSubmit = async (formValues: AccountDetailsFormState) => {
    try {
      if (accountToEdit) {
        await updatePersonalAccountConnectedPhone({
          id: id!,
          connectedPhoneId: accountToEdit.id,
          ...mapAccountToSubmitUpdate(formValues),
        }).unwrap()
      } else {
        await createPersonalAccountConnectedPhone({
          id: id!,
          ...mapAccountToSubmitCreate(formValues),
        }).unwrap()
      }

      toggleAccountDetailsModalVisible()
      setAccountToEdit(null)
    } catch {
      alert('Помилка при збереженні')
    }
  }

  const handleAccountDetailsModalClose = () => {
    toggleAccountDetailsModalVisible()
    setAccountToEdit(null)
  }

  // --- computed ---
  const shouldShowFinanceBlock =
    personalAccount &&
    personalAccount.supplyType === SupplyType.NATURAL_GAS &&
    personalAccount.personType === PersonType.NATURAL_PERSON

  const disableAddButton = React.useMemo(() => {
    if (!request) return true

    return connectedPhones?.some((phone) => String(phone.phone) === String(request.incomePhoneNumber))
  }, [connectedPhones, request])

  const accountDetailsModalDefaultValues = React.useMemo(() => {
    if (!accountToEdit) return undefined

    return mapAccountToEdit(accountToEdit)
  }, [accountToEdit])

  const requestFormDefaultValues = React.useMemo(() => {
    if (!request) return undefined

    return mapRequestToEdit(request, personalAccount)
  }, [request, personalAccount])

  const disableForm = React.useMemo(() => {
    if (!request) return true

    if (requestId) {
      return !isSupervisorInCompany(request?.company)
    }

    return !isInCompany(internalCompany!)
  }, [internalCompany, isInCompany, isSupervisorInCompany, request, requestId])

  if (!personalAccount) {
    return <Skeleton rows={6} />
  }

  return (
    <PageWrapper className={clsx(classes.container, { [classes.withFinance]: shouldShowFinanceBlock })}>
      <Helmet
        title={
          requestId
            ? `Перегляд звернення: ${personalAccount.ownerName || personalAccount.number}`
            : `Нове звернення: ${personalAccount.ownerName || personalAccount.number}`
        }
        link={[{ rel: 'icon', type: 'image/png', href: requestId ? editRequestIcon : addRequestIcon }]}
      />
      <RequestForm
        className={classes.form}
        onSubmit={handleSubmitRequestForm}
        defaultValues={requestFormDefaultValues}
        disabled={disableForm}
        controlProps={{
          supplyType: { disabled: true },
          // if edit request - disable next fields
          keywords: { disabled: !!requestId },
          escalation: { disabled: !!requestId },
        }}
      />
      <RequestDetails
        className={classes.details}
        id={request?.callId ?? callId}
        number={request?.number}
        createdAt={request?.createdAt}
        company={request?.company}
        incomePhoneNumber={request?.incomePhoneNumber}
        contactCenterPhoneNumber={request?.contactCenterPhoneNumber}
        authorFullName={request?.createdBy}
        editorFullName={request?.editedBy}
        agentPhone={request?.agentPhoneNumber ? String(request?.agentPhoneNumber) : agentPhoneNumber}
        team={request?.team || team}
      />
      <h1>Особовий рахунок №{personalAccount.number}</h1>
      <PersonalAccountDetails className={classes.personalAccountDetails} personalAccount={personalAccount} />
      {shouldShowFinanceBlock && (
        <>
          <PersonalAccountFinance
            className={classes.personalAccountFinance}
            balance={balance?.balance ?? 0}
            accrualsForLastPeriod={balance?.accrualForLastPeriod ?? 0}
          />
          <PersonalAccountMeterReadings
            className={classes.personalAccountMeterReadings}
            onAdd={toggleNewReadingModalVisible}
            readings={meterReading?.readings || []}
          />
        </>
      )}
      <PersonalAccountConnectedAccounts
        accounts={connectedPhones}
        onEdit={handlePersonalAccountConnectedAccountsEdit}
        onAdd={toggleAccountDetailsModalVisible}
        className={classes.personalAccountConnectedAccounts}
        disableAddButton={disableAddButton}
      />
      <PersonalAccountHistory
        className={classes.personalAccountHistory}
        personalAccountId={+id!}
        scrollableHistory
        rounded
        limit={10}
      />

      {/* modals */}
      <NewReadingModal
        visible={newReadingModalVisible}
        onClose={toggleNewReadingModalVisible}
        onSubmit={handleNewReadingModalSubmit}
        type={meterReading?.currentType ?? MeterType.ONE_ZONE}
      />
      <AccountDetailsModal
        visible={accountDetailsModalVisible}
        onClose={handleAccountDetailsModalClose}
        onSubmit={handleAccountDetailsModalSubmit}
        phoneEdit={!accountToEdit}
        defaultValues={accountDetailsModalDefaultValues}
      />
    </PageWrapper>
  )
}
