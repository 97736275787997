import React from 'react'
import { useAccount } from 'hooks/useAccount'
import { Navigate } from 'react-router-dom'
import { routeList } from 'routes'

export const withSupervisorRoleRestriction = (WrappedComponent: React.FC) => {
  const RestrictedComponent: React.FC = (props) => {
    const { isSupervisor } = useAccount()

    if (isSupervisor) {
      return <WrappedComponent {...props} />
    }

    return <Navigate replace to={routeList.noAccess} />
  }

  return RestrictedComponent
}
