export const flex = (
  direction: 'row' | 'row-reverse' | 'column' | 'column-reverse' = 'row',
  alignItems: 'normal' | 'center' | 'end' | 'stretch' | 'baseline-position' | 'overflow-position' | 'self-position' = 'normal',
  justifyContent:
    | 'normal'
    | 'center'
    | 'content-distribution'
    | 'space-between'
    | 'overflow-position'
    | 'content-position'
    | 'left'
    | 'right' = 'normal',
  flexWrap: 'nowrap' | 'wrap' | 'wrap-reverse' = 'nowrap',
  display: 'flex' | 'inline-flex' = 'flex'
) => {
  return {
    display,
    flexDirection: direction,
    alignItems,
    justifyContent,
    flexWrap,
  }
}

export const box = (width: string | number = 'auto', height: string | number = width) => {
  return {
    width,
    height,
  }
}
