import React from 'react'
import { ActionButton, PrimaryButton, TextField } from '@fluentui/react'
import ReactDatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import format from 'date-fns/format'
import uk from 'date-fns/locale/uk'

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-icon.svg'

import { useDatePickerStyles } from './styles'

type DatePickerDate = Date | null | undefined

interface RangeDatePickerProps<CustomModifierNames extends string = never>
  extends Omit<ReactDatePickerProps<CustomModifierNames, true>, 'onChange'> {
  onChange?: (dates: [DatePickerDate, DatePickerDate]) => void
  label?: string
  errorMessage?: string
}

registerLocale('uk', uk)

export const RangeDatePicker = <CustomModifierNames extends string = never>({
  className,
  onChange,
  startDate,
  endDate,
  label,
  placeholderText,
  errorMessage,
  ...props
}: RangeDatePickerProps<CustomModifierNames>) => {
  const classes = useDatePickerStyles()
  const datepickerRef = React.useRef<ReactDatePicker<CustomModifierNames, true>>(null)
  const [localStartValue, setLocalStartValue] = React.useState<DatePickerDate>(startDate)
  const [localEndValue, setLocalEndValue] = React.useState<DatePickerDate>(endDate)

  const handleSubmit = () => {
    onChange?.([localStartValue, localEndValue])
    datepickerRef.current?.setOpen(false)
  }

  const handleCancel = () => {
    onChange?.([startDate, endDate])
    setLocalStartValue(startDate)
    setLocalEndValue(endDate)
    datepickerRef.current?.setOpen(false)
  }

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates
    setLocalStartValue(start)
    setLocalEndValue(end)
  }

  return (
    <div className={className}>
      <ReactDatePicker<CustomModifierNames, true>
        {...props}
        placeholderText="Оберіть період"
        ref={datepickerRef}
        dateFormat="dd/MM/yyyy"
        selectsRange
        wrapperClassName={classes.wrapper}
        startDate={localStartValue}
        endDate={localEndValue}
        onChange={handleDateChange}
        showPopperArrow={false}
        shouldCloseOnSelect={false}
        calendarClassName={classes.calendar}
        locale="uk"
        disabledKeyboardNavigation
        renderDayContents={(day) => {
          return <div className={'datepicker-day'}>{day}</div>
        }}
        onClickOutside={handleCancel}
        customInput={<DatePickerCustomInput label={label} placeholder={placeholderText} errorMessage={errorMessage} />}
        renderCustomHeader={({ increaseYear, decreaseYear, increaseMonth, decreaseMonth, date }) => {
          return (
            <div className={classes.header}>
              <div className={classes.headerControls}>
                <button className={classes.headerButton} type="button" onClick={decreaseMonth}>
                  <ArrowIcon style={{ transform: 'rotate(180deg)' }} />
                </button>
                <span className={classes.headerValue}>{format(date, 'MMMM', { locale: uk })}</span>
                <button className={classes.headerButton} type="button" onClick={increaseMonth}>
                  <ArrowIcon />
                </button>
              </div>

              <div className={classes.headerControls}>
                <button className={classes.headerButton} type="button" onClick={decreaseYear}>
                  <ArrowIcon style={{ transform: 'rotate(180deg)' }} />
                </button>
                <span className={classes.headerValue}>{format(date, 'yyyy', { locale: uk })}</span>
                <button className={classes.headerButton} type="button" onClick={increaseYear}>
                  <ArrowIcon />
                </button>
              </div>
            </div>
          )
        }}
      >
        <PrimaryButton type="button" onClick={handleSubmit}>
          Підтвердити
        </PrimaryButton>
        <ActionButton type="button" onClick={handleCancel}>
          Скасувати
        </ActionButton>
      </ReactDatePicker>
    </div>
  )
}

interface DatePickerCustomInputProps {
  value?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  label?: string
  placeholder?: string
  errorMessage?: string
}

const DatePickerCustomInput = React.forwardRef(
  (
    { value, onClick, label, placeholder, errorMessage }: DatePickerCustomInputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <TextField
        elementRef={ref}
        readOnly
        label={label}
        placeholder={placeholder}
        errorMessage={errorMessage}
        value={value}
        onClick={onClick}
      />
    )
  }
)
