import { Helmet } from 'components/Helmet'
import { PageWrapper } from 'components/PageWrapper'
import houseIcon from 'assets/icons/house-icon.svg'

export const NotFoundPage = () => {
  return (
    <PageWrapper>
      <Helmet title="Сторінка не знайдена" link={[{ rel: 'icon', type: 'image/png', href: houseIcon }]} />
      <h1>Сторінка не знайдена!</h1>
    </PageWrapper>
  )
}
