import { makeStyles } from '@fluentui/react'
import { fonts, colors } from 'styles/variables'

export const useNavBarStyles = makeStyles({
  nav: {
    marginBottom: '24px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  list: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  listItem: {
    margin: '0 8px',
  },
  link: {
    ...fonts.body2,
    textDecoration: 'none',
    color: colors.grey14,
  },
})
