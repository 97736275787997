import React from 'react'
import { DefaultButton, DialogFooter, PrimaryButton, TextField } from '@fluentui/react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useReadingFormStyles } from './style'

const requiredMessage = "Це поле обов'язкове"

const threeZoneMeterReadingFormSchema = z.object({
  peakZoneConsumption: z.string({ required_error: requiredMessage }),
  halfPeakZoneConsumption: z.string({ required_error: requiredMessage }),
  nightZoneConsumption: z.string({ required_error: requiredMessage }),
})

export type ThreeZoneMeterReadingFormState = z.infer<typeof threeZoneMeterReadingFormSchema>

interface ThreeZoneMeterReadingFormProps {
  onCancel: () => void
  onSubmit: (formValues: ThreeZoneMeterReadingFormState) => void
}

export const ThreeZoneMeterReadingForm: React.FC<ThreeZoneMeterReadingFormProps> = ({ onCancel, onSubmit }) => {
  const classes = useReadingFormStyles()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ThreeZoneMeterReadingFormState>({
    resolver: zodResolver(threeZoneMeterReadingFormSchema),
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.formFields}>
        <Controller
          name="peakZoneConsumption"
          control={control}
          render={(props) => (
            <TextField
              {...props.field}
              errorMessage={errors?.[props.field?.name]?.message as string}
              label="Пік"
              placeholder="Введіть пікові показання"
            />
          )}
        />
        <Controller
          name="halfPeakZoneConsumption"
          control={control}
          render={(props) => (
            <TextField
              {...props.field}
              errorMessage={errors?.[props.field?.name]?.message as string}
              label="Напівпік"
              placeholder="Введіть напівпікові показання"
            />
          )}
        />
        <Controller
          name="nightZoneConsumption"
          control={control}
          render={(props) => (
            <TextField
              {...props.field}
              errorMessage={errors?.[props.field?.name]?.message as string}
              label="Ніч"
              placeholder="Введіть нічні показання"
            />
          )}
        />
      </div>
      <DialogFooter styles={{ actionsRight: { justifyContent: 'flex-start' } }} >
        <PrimaryButton text="Зберегти показання" type="submit" />
        <DefaultButton onClick={onCancel} type="reset" text="Скасувати передачу" />
      </DialogFooter>
    </form>
  )
}
