import { ContractType } from 'types'

const contractTypeLabels: Record<ContractType, string> = {
  [ContractType.UNIVERSAL]: 'Постачання універсальної послуги',
  [ContractType.UNREGULATED]: 'Постачання нерегульованого тарифу',
}

export const getContactTypeLabel = (contractType?: ContractType): string => {
  if (!contractType) return ''
  return contractTypeLabels[contractType]
}
