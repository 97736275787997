import React from 'react'
import { ITextField, PrimaryButton, TextField } from '@fluentui/react'
import { nanoid } from 'nanoid'
import clsx from 'clsx'

import { ReactComponent as RemoveIcon } from 'assets/icons/remove-icon.svg'
import type { Option } from 'types'

import { useTagInputStyles } from './styles'

interface TagInputProps {
  label?: string
  disabled?: boolean
  placeholder?: string
  value?: Option[]
  onChange: (value: Option[]) => void
  className?: string
  errorMessage?: string
  hideLabel?: boolean
}

export const TagInput: React.FC<TagInputProps> = ({
  label,
  onChange,
  value = [],
  placeholder,
  disabled,
  className,
  errorMessage,
  hideLabel = false,
}) => {
  const classes = useTagInputStyles()
  const [inputValue, setInputValue] = React.useState('')
  const inputRef = React.useRef<ITextField>(null)
  const valueAlreadyExists = React.useMemo(() => {
    const hasExactValue = value.some((el) => el.text === inputValue)

    if (hasExactValue) {
      return true
    }

    const newValues = inputValue
      .split(';')
      .map((part) => part.trim())
      .filter(Boolean)

    const isNewValuesUniq = newValues.every((el) => !value.some((value) => value.text === el))
    const isInputValuesUniq = newValues.every((el, index) => newValues.indexOf(el) === index)

    return !isNewValuesUniq || !isInputValuesUniq
  }, [inputValue, value])

  const handlePlus = () => {
    if (!inputValue.trim() || valueAlreadyExists || disabled) {
      inputRef.current?.focus()
      return
    }

    setInputValue('')
    const newValues = inputValue
      .split(';')
      .map((part) => part.trim())
      .filter(Boolean)
      .map((text) => ({ text: text, key: nanoid() }))
    onChange([...value, ...newValues])
    inputRef.current?.focus()
  }

  const handleRemove = (key: string) => {
    onChange(value.filter((el) => el.key !== key))
  }

  return (
    <div className={clsx(classes.tagInput, className, { disabled })}>
      {disabled ? (
        !hideLabel && <div className={classes.tagLabel}>{label}</div>
      ) : (
        <div className={classes.inputContainer}>
          <TextField
            label={label}
            disabled={disabled}
            componentRef={inputRef}
            placeholder={placeholder}
            value={inputValue}
            onChange={(e, value) => setInputValue(value ?? '')}
            className={classes.input}
            errorMessage={errorMessage}
          />
          <PrimaryButton disabled={valueAlreadyExists || disabled} className={classes.addButton} onClick={handlePlus}>
            +
          </PrimaryButton>
        </div>
      )}
      <div className={classes.tagsContainer}>
        {value.map((el, index) => (
          <div key={index} className={classes.tag}>
            {el.text}
            {!disabled && (
              <button
                type="button"
                disabled={disabled}
                className={classes.tagButton}
                onClick={() => handleRemove(el.key)}
              >
                <RemoveIcon />
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
