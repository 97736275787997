import { cacher } from 'utils/rtkQueryCacheUtils'
import { api } from '../api'
import type { GetPersonalAccountResponseDto } from './dtos/GetPersonalAccount'
import type {
  GetPersonalAccountsListRequestDto,
  GetPersonalAccountsListResponseDto,
} from './dtos/GetPersonalAccountsList'
import type { GetPersonalAccountConnectedPhonesResponseDto } from './dtos/GetPersonalAccountConnectedPhones'
import type { UpdatePersonalAccountConnectedPhonesRequestDto } from './dtos/UpdatePersonalAccountConnectedPhones'
import type { GetPersonalAccountMeterReadingResponseDto } from './dtos/GetPersonalAccountMeterReadings'
import type { CreatePersonalAccountMeterReadingRequestDto } from './dtos/CreatePersonalAccountMeterReading'
import type { GetPersonalAccountBalanceResponseDto } from './dtos/GetPersonalAccountBalanceResponseDto'
import type { CreatePersonalAccountConnectedPhonesRequestDto } from './dtos/CreatePersonalAccountConnectedPhonesRequestDto'

const personalAccountsEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    getPersonalAccount: builder.query<GetPersonalAccountResponseDto, string>({
      query: (id) => ({ url: `/personal-accounts/${id}`, method: 'GET' }),
      providesTags: cacher.cacheByIdArg('PERSONAL_ACCOUNTS'),
    }),
    getPersonalAccountBalance: builder.query<GetPersonalAccountBalanceResponseDto, string>({
      query: (id) => ({ url: `/personal-accounts/${id}/balance`, method: 'GET' }),
    }),
    getPersonalAccountsList: builder.query<GetPersonalAccountsListResponseDto, GetPersonalAccountsListRequestDto>({
      query: (params) => ({ url: '/personal-accounts', params, method: 'GET' }),
      providesTags: cacher.providesList('PERSONAL_ACCOUNTS'),
    }),
    // meter reading
    getPersonalAccountMeterReading: builder.query<GetPersonalAccountMeterReadingResponseDto, string>({
      query: (id) => ({ url: `/personal-accounts/${id}/meter-readings`, method: 'GET' }),
      providesTags: cacher.cacheByIdArg('PERSONAL_ACCOUNTS_METER_READINGS'),
    }),
    createPersonalAccountMeterReading: builder.mutation<void, CreatePersonalAccountMeterReadingRequestDto>({
      query: ({ id, ...data }) => ({ url: `/personal-accounts/${id}/meter-readings`, data, method: 'POST' }),
      invalidatesTags: cacher.invalidatesList('PERSONAL_ACCOUNTS_METER_READINGS'),
    }),
    // connected phones
    getPersonalAccountConnectedPhones: builder.query<GetPersonalAccountConnectedPhonesResponseDto, string>({
      query: (id) => ({ url: `/personal-accounts/${id}/connected-phones`, method: 'GET' }),
      providesTags: cacher.cacheByIdArg('PERSONAL_ACCOUNTS_CONNECTED_PHONES'),
    }),
    createPersonalAccountConnectedPhone: builder.mutation<void, CreatePersonalAccountConnectedPhonesRequestDto>({
      query: ({ id, ...data }) => ({ url: `/personal-accounts/${id}/connected-phones`, data, method: 'POST' }),
      invalidatesTags: cacher.cacheByIdArgProperty('PERSONAL_ACCOUNTS_CONNECTED_PHONES'),
    }),
    updatePersonalAccountConnectedPhone: builder.mutation<void, UpdatePersonalAccountConnectedPhonesRequestDto>({
      query: ({ id, connectedPhoneId, ...data }) => ({
        url: `/personal-accounts/${id}/connected-phones/${connectedPhoneId}`,
        data,
        method: 'PUT',
      }),
      invalidatesTags: cacher.cacheByIdArgProperty('PERSONAL_ACCOUNTS_CONNECTED_PHONES'),
    }),
  }),
})

export const {
  useGetPersonalAccountQuery,
  useGetPersonalAccountBalanceQuery,
  useGetPersonalAccountsListQuery,
  useGetPersonalAccountConnectedPhonesQuery,
  useUpdatePersonalAccountConnectedPhoneMutation,
  useGetPersonalAccountMeterReadingQuery,
  useCreatePersonalAccountMeterReadingMutation,
  useCreatePersonalAccountConnectedPhoneMutation,
} = personalAccountsEndpoints
