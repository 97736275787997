import React from 'react'
import { Helmet } from 'components/Helmet'
import { useLazyGetReportsByThemeQuery, useDownloadReportsByThemeMutation } from 'store/reports'
import { Skeleton } from 'components/Skeleton'
import { PageWrapper } from 'components/PageWrapper'
import { ReportPageFilters, ReportPageFiltersFormState } from 'components/ReportPageFilters'
import { withSupervisorRoleRestriction } from 'hocs/withSupervisorRoleRestriction'
import reportIcon from 'assets/icons/report-icon.svg'
import { mapReportByThemeToSubmit } from 'utils/mappers/mapReportToSubmit'

import { ReportByTheme } from './components/ReportByTheme'

export const ReportsByThemePage = withSupervisorRoleRestriction(() => {
  const [formState, setFormState] = React.useState<ReportPageFiltersFormState>()
  const [generateReport, { data, isLoading }] = useLazyGetReportsByThemeQuery()
  const [downloadReport] = useDownloadReportsByThemeMutation()

  const handleSubmit = async (formState: ReportPageFiltersFormState) => {
    await generateReport(mapReportByThemeToSubmit(formState))
    setFormState(formState)
  }

  const handleDownload = async () => {
    if (!formState) return
    await downloadReport(mapReportByThemeToSubmit(formState))
  }

  return (
    <PageWrapper>
      <Helmet title="Звіти" link={[{ rel: 'icon', type: 'image/png', href: reportIcon }]} />
      <ReportPageFilters onSubmit={handleSubmit} />
      {isLoading && <Skeleton rows={6} />}
      {data && (
        <ReportByTheme
          data={[
            ...data.items,
            {
              theme: 'Всього',
              subTheme: '-',
              category: '-',
              requestsCount: data.total.requestsCount,
            },
          ]}
          onDownload={handleDownload}
        />
      )}
    </PageWrapper>
  )
})
