import { makeStyles } from '@fluentui/react'
import { fonts, space } from 'styles/variables'

export const useRequestFormStyles = makeStyles({
  title: {
    ...fonts.title3,
    marginBottom: space.md,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: space.xs,
  },
  supplyType: {
    width: '272px',
  },
  requestType: {
    width: '272px',
  },
  requestReasonType: {
    width: '272px',
  },
  theme: {
    width: '272px',
  },
  subTheme: {
    width: '272px',
  },
  category: {
    width: '272px',
  },
  comment: {
    width: '552px',
  },
  keywords: {
    width: '272px',
  },
  escalation: {
    width: '272px',
  },
  submit: {
    alignSelf: 'end',
  },
})
