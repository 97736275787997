import React from 'react'
import clsx from 'clsx'

import { useTableStyles } from './styles'

interface DataType {
  id?: string
}

export interface Column<T extends DataType> {
  id: keyof T
  label: React.ReactNode
  width?: string
  className?: string
  title?: (value: any, row: T, index: number) => string
  render?: (value: any, row: T, index: number) => React.ReactNode
}

interface TableProps<T extends DataType> {
  data: T[]
  className?: string
  showHeader?: boolean
  columns: Column<T>[]
}

export const Table = <T extends DataType>({ className, data, columns, showHeader }: TableProps<T>) => {
  const classes = useTableStyles()

  return (
    <table className={className}>
      {showHeader && (
        <thead>
          <tr>
            {columns.map((column) => {
              return (
                <th
                  key={String(column.id)}
                  style={{ width: column.width }}
                  className={clsx(column.className, classes.theadTr)}
                >
                  {column.label}
                </th>
              )
            })}
          </tr>
        </thead>
      )}
      <tbody>
        {data.map((row, index) => {
          return (
            <tr key={row.id}>
              {columns.map((column) => {
                const value = column.render
                  ? column.render(row[column.id], row, index)
                  : (row[column.id] as React.ReactNode)

                return (
                  <td
                    key={String(column.id)}
                    width={column.width}
                    className={clsx(column.className, classes.tbodyTr)}
                    title={column?.title?.(row[column.id], row, index)}
                  >
                    {value}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
