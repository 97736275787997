import { makeStyles } from '@fluentui/react'
import { colors, fonts } from 'styles/variables'

export const usePersonalAccountDetailsStyles = makeStyles({
  title: {
    ...fonts.subtitle1,
    margin: 0,
  },
  group: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    marginBottom: '8px',
    dt: {
      ...fonts.body1,
      fontWeight: 600,
      color: colors.grey14,
    },
  },
  bold: {
    fontWeight: 'bold',
  },
})
