import { makeStyles } from '@fluentui/react'

const MAX_WIDTH = '1040px'

export const useReportResultLayoutStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
  },
  title: {
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28px',
    maxWidth: '824px',
  },
  children: {
    maxWidth: MAX_WIDTH,
    width: '100%',
  },
})
