import React from 'react'
import clsx from 'clsx'

import { useBoxStyles } from './styles'

interface BoxProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
}

export const Box: React.FC<BoxProps> = ({ children, className }) => {
  const classes = useBoxStyles()

  return <div className={clsx(classes.container, className)}>{children}</div>
}
