/**
 * Env variables initialization module
 * @remark f the value of at least one variable is not found,
 * The application will immediately throw an error when the module is initialized
 * @module
 */

interface GetEnvOptions {
  required?: boolean
}

/**
 * Get env variable
 * @throwable
 */
const getEnvVar = (key: string, options?: GetEnvOptions) => {
  const { required } = options || {}
  if (required && process.env[key] === undefined) {
    throw new Error(`Env variable ${key} is required`)
  }
  return process.env[key] || ''
}

export const env = {
  API_URL: getEnvVar('REACT_APP_API_URL', { required: true }),
  CLIENT_ID: getEnvVar('REACT_APP_CLIENT_ID', { required: true }),
  TENANT_ID: getEnvVar('REACT_APP_TENANT_ID', { required: true }),
  TOKEN_SCOPE: getEnvVar('REACT_APP_TOKEN_SCOPE', { required: true }),
}
