import React from 'react'
import { PrimaryButton } from '@fluentui/react'
import { Box } from 'components/Box'

interface ErrorProps {
  error?: any
}

export const Error: React.FC<ErrorProps> = ({ error }) => {
  const [expandError, setExpandError] = React.useState(false)

  return (
    <div>
      <h2 style={{ textAlign: 'center' }}>Щось пішло не так</h2>
      {error && (
        <Box>
          <PrimaryButton onClick={() => setExpandError(!expandError)}>
            {expandError ? 'Приховати деталі' : 'Показати деталі'}
          </PrimaryButton>
          {expandError && <pre>{JSON.stringify(error, null, 2)}</pre>}
        </Box>
      )}
    </div>
  )
}
