import React from 'react'
import clsx from 'clsx'

import { NavBar } from 'components/NavBar'
import { usePageWrapperStyles } from './styles'

interface PageWrapperProps {
  className?: string
  children: React.ReactNode
}

export const PageWrapper: React.FC<PageWrapperProps> = ({ children, className }) => {
  const classes = usePageWrapperStyles()

  return (
    <div className={clsx(classes.wrapper)}>
      <NavBar />
      <div className={className}>{children}</div>
    </div>
  )
}
