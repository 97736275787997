import { makeStyles } from '@fluentui/react'
import { colors, borderRadius, space } from 'styles/variables'
import { flex } from 'styles/utils'

export const usePaginationStyles = makeStyles(() => ({
  pagination: {
    ...flex('row', 'center'),
    gap: space.xxs,
    listStyle: 'none',
    padding: '0',
  },
  page: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.grey94}`,
    borderRadius: borderRadius[1],
  },
  activePage: {
    color: colors.white,
    background: colors.comm80,
    borderColor: colors.comm80,
  },
  break: {
    height: '32px',
    minWidth: '32px',
    ...flex('row', 'center', 'center'),
    backgroundColor: colors.white,
    borderRadius: borderRadius[1],
    border: `1px solid ${colors.grey94}`,
  },
  link: {
    height: '32px',
    minWidth: '16px',
    padding: '0 8px',
    ...flex('row', 'center', 'center'),
    cursor: 'pointer',
  },
  disabledLink: {
    cursor: 'not-allowed',
    '& svg': {
      fill: colors.grey86,
    },
  },
}))
