import { Helmet } from 'components/Helmet'
import { NoAccess } from 'components/NoAccess'
import houseIcon from 'assets/icons/house-icon.svg'

export const NoAccessPage = () => {
  return (
    <>
      <Helmet title="Немає доступу" link={[{ rel: 'icon', type: 'image/png', href: houseIcon }]} />
      <NoAccess />
    </>
  )
}
