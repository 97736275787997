import type { AccountDetailsFormState } from 'components/AccountDetailsModal'
import type {
  CreatePersonalAccountConnectedPhonesRequestDto,
  UpdatePersonalAccountConnectedPhonesRequestDto,
} from 'store/personal-accounts'

export const mapAccountToSubmitCreate = (
  formValues: AccountDetailsFormState
): Omit<CreatePersonalAccountConnectedPhonesRequestDto, 'id'> => {
  return {
    phone: +formValues.phone,
    type: formValues.type,
    stopList: !!formValues.stopList,
    ownership: formValues.ownership,
    reason: formValues.reason ?? undefined,
    comment: formValues.comment ?? '',
  }
}

export const mapAccountToSubmitUpdate = (
  formValues: AccountDetailsFormState
): Omit<UpdatePersonalAccountConnectedPhonesRequestDto, 'id' | 'connectedPhoneId'> => {
  return {
    type: formValues.type,
    stopList: !!formValues.stopList,
    ownership: formValues.ownership,
    reason: formValues.reason ?? undefined,
    comment: formValues.comment ?? '',
  }
}
