import { Option, CompanyType } from 'types'

const companyTypeLabel: Record<CompanyType, string> = {
  [CompanyType.KYIV_ENERGY_SERVICES]: 'Київські енергетичні послуги',
  [CompanyType.DNIPRO_ENERGY_SERVICES]: 'Дніпровські енергетичні послуги',
  [CompanyType.DONETSK_ENERGY_SERVICES]: 'Донецькі енергетичні послуги',
  [CompanyType.YASNO]: 'Ясно+',
}

export const getCompanyTypeLabel = (companyType?: CompanyType): string => {
  if (!companyType) return ''
  return companyTypeLabel[companyType]
}

export const companyTypeList = [
  CompanyType.KYIV_ENERGY_SERVICES,
  CompanyType.DNIPRO_ENERGY_SERVICES,
  CompanyType.DONETSK_ENERGY_SERVICES,
  CompanyType.YASNO,
] as const

export const companyTypeOptions: Option<CompanyType>[] = companyTypeList.map((key) => ({
  key,
  text: getCompanyTypeLabel(key),
}))
