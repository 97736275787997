import { makeStyles } from '@fluentui/react'
import { borderRadius, space, fonts, colors, shadows } from 'styles/variables'

export const usePersonalAccountListItemStyles = makeStyles(() => ({
  container: {
    borderRadius: borderRadius[2],
    overflow: 'hidden',
    marginBottom: space.sm,
  },
  innerContainer: {
    background: colors.white,
    boxShadow: shadows[1],
    padding: space.md,
    display: 'grid',
    gap: space.md,
    alignItems: 'center',
    gridTemplateColumns: '1fr 5fr 25fr 3fr 4fr 5fr',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
  },
  history: {
    background: colors.grey98,
    padding: space.md,
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: space.xs,
  },
  blockingReason: {
    ...fonts.caption1,
    color: colors.red,
  },
  number: {
    ...fonts.subtitle2,
  },
  personType: {
    ...fonts.body1,
  },
  personFullName: {
    ...fonts.subtitle2,
  },
  address: {
    ...fonts.body1,
  },
  requestsCount: {
    ...fonts.body2,
  },
  requestDate: {
    ...fonts.body2,
  },
}))

export const usePersonalAccountHistoryStyles = makeStyles(() => ({
  container: {
    padding: space.md,
    background: colors.grey98,
  },
  title: {
    ...fonts.body1,
    fontWeight: 700,
  },
  rounded: {
    borderRadius: borderRadius[2],
  },
}))

export const usePersonalAccountHistoryListStyles = makeStyles(() => ({
  left: {
    ...fonts.body1,
    fontWeight: 600,
    color: colors.grey14,
    textDecoration: 'none',
  },
  agent: {
    ...fonts.body1,
    fontWeight: `600 !important`,
  },
  link: {
    color: colors.comm80,
  },
  list: {
    maxHeight: '210px',
    height: '100%',
    overflowY: 'auto',
  },
  scrollable: {
    maxHeight: 'none',
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '7fr 8fr 26fr 15fr 13fr 33fr',
    gap: 8,
  },
  headerItem: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    color: '#707070',
    textAlign: 'left',
    padding: '4px 0',
  },
  rowItem: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    color: '#242424',
    textAlign: 'left',
    padding: '4px 0',
  },
}))
