interface User {
  firstName: string
  middleName: string
  lastName: string
}

export function buildFullName(user: User): string
export function buildFullName(userOrFirstName: User | string, middleName?: string, lastName?: string): string {
  if (typeof userOrFirstName === 'object') {
    return `${userOrFirstName.lastName || ''} ${userOrFirstName.firstName || ''} ${
      userOrFirstName.middleName || ''
    }`.trim()
  }

  return `${lastName || ''} ${userOrFirstName || ''} ${middleName || ''}`.trim()
}
