import { nanoid } from 'nanoid'

import { RequestType, EscalationStatusType } from 'types'
import type { RequestFormState } from 'components/RequestForm'
import type { PersonalAccount } from 'store/personal-accounts'
import type { GetRequestResponseDto } from 'store/requests'

export const mapRequestToEdit = (
  request: GetRequestResponseDto,
  personalAccount?: Pick<PersonalAccount, 'supplyType'>
): RequestFormState => {
  return {
    supplyType: request.supplyType ?? personalAccount?.supplyType,
    channelType: request.type ?? RequestType.INCOMING_CALL,
    reasonType: request.reasonType,
    themeId: request.themeId!,
    subThemeId: request.subThemeId!,
    categoryId: request.categoryId,
    comment: request.comment,
    keywords: request.keywords.map((keyword) => ({
      key: nanoid(),
      text: keyword,
    })),
    escalation: request.escalation ?? EscalationStatusType.NOT_REQUIRED,
  }
}
