import { PersonType } from 'types'
import { useGetLegalEntityContactQuery, useGetNaturalPersonContactQuery } from '../endpoints'
import type { LegalEntityContact, NaturalPersonContact } from '../dtos/GetContactDto'

export const useGetContactQuery = (id: string) => {
  const naturalPersonContactQuery = useGetNaturalPersonContactQuery(id)
  const legalEntityContactQuery = useGetLegalEntityContactQuery(id)

  if (naturalPersonContactQuery.data || legalEntityContactQuery.data) {
    if (naturalPersonContactQuery.data) {
      return {
        ...naturalPersonContactQuery,
        data: {
          ...naturalPersonContactQuery.data,
          type: PersonType.NATURAL_PERSON,
        } as NaturalPersonContact,
      }
    }

    if (legalEntityContactQuery.data) {
      return {
        ...legalEntityContactQuery,
        data: {
          ...legalEntityContactQuery.data,
          type: PersonType.LEGAL_ENTITY,
        } as LegalEntityContact,
      }
    }
  }

  return {
    isLoading: naturalPersonContactQuery.isLoading || legalEntityContactQuery.isLoading,
    data: undefined,
  }
}
