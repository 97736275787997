export const colors = Object.freeze({
  white: '#FFFFFF',

  grey14: '#242424',
  grey38: '#616161',
  grey44: '#707070',
  grey80: '#BDBDBD',
  grey82: '#D1D1D1',
  grey86: '#DBDBDB',
  grey90: '#999999',
  grey94: '#F0F0F0',
  grey98: '#FAFAFA',
  grey99: '#F5F5F5',

  comm70: '#115EA3',
  comm80: '#0F6CBD',
  comm140: '#B4D6FA',
  comm150: '#CFE4FA',
  comm160: '#EBF3FC',

  red: 'red',
})

export const fonts = Object.freeze({
  body1: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
  },
  body2: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '22px',
  },
  caption1: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
  },
  subtitle1: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '28px',
  },
  subtitle2: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '22px',
  },
  title2: {
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '36px',
  },
  title3: {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '32px',
  },
})

export const borderRadius = Object.freeze({
  1: '8px',
  2: '16px',
})

export const space = Object.freeze({
  xxs: '4px',
  xs: '8px',
  sm: '12px',
  md: '16px',
  lg: '20px',
  xl: '24px',
  xxl: '28px',
  xxxl: '32px',
})

export const shadows = Object.freeze({
  1: '0px 4px 10px 0px rgba(19, 36, 48, 0.07)',
})
