import React from 'react'
import { Dialog } from '@fluentui/react'

import { useAccountDetailsModalStyles } from './styles'
import { AccountDetailsForm, AccountDetailsFormProps } from './AccountDetailsForm'

interface EditAccountDetailsModalProps extends AccountDetailsFormProps {
  visible: boolean
  onClose: () => void
}

export const AccountDetailsModal: React.FC<EditAccountDetailsModalProps> = ({
  visible,
  onClose,
  onSubmit,
  defaultValues,
  phoneEdit = true,
}) => {
  const classes = useAccountDetailsModalStyles()

  return (
    <Dialog
      styles={{ main: classes.modal }}
      maxWidth="100%"
      hidden={!visible}
      onDismiss={onClose}
      dialogContentProps={{
        showCloseButton: true,
      }}
    >
      <AccountDetailsForm onSubmit={onSubmit} defaultValues={defaultValues} phoneEdit={phoneEdit} />
    </Dialog>
  )
}
