import { makeStyles } from '@fluentui/react'
import { colors, fonts } from 'styles/variables'

export const useFiltersStyles = makeStyles({
  container: {
    marginBottom: '24px',
  },
  filtersTypeContainer: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  tabs: {
    flex: '1',
  },
  reportTypeLabel: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '28px',
  },
  fieldsContainer: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '16px',
  },
  company: {
    width: '290px',
  },
  period: {
    width: '260px',
  },
  supplyType: {
    width: '195px',
  },
  requestType: {
    width: '188px',
  },
  personType: {
    width: '187px',
  },
  keywords: {
    width: '340px',
  },
  reportByDataText: {
    ...fonts.body2,
    color: colors.grey14,
    margin: 0,
  },
})
