import { makeStyles } from '@fluentui/react'
import { fonts, colors } from 'styles/variables'

export const useReportByThemeTableStyles = makeStyles({
  table: {
    borderCollapse: 'collapse',

    thead: {
      tr: {
        ...fonts.body1,
        heigh: '20px',
        color: colors.grey44,
      },
    },
    tbody: {
      tr: {
        ...fonts.body1,
        padding: '16px 0',
        height: '52px',
        borderBottom: `1px solid #E6E6E6`,
        color: colors.grey14,
      },
    },
  },
  themeTable: {
    width: '100%',
  },
  keywordTable: {
    maxWidth: '400px',
  },
})
