import React from 'react'
import { IconButton, PrimaryButton } from '@fluentui/react'

import { BlockReasonType, ConnectedPhoneType, OwnershipType, Phone } from 'types'
import { ReactComponent as PenIcon } from 'assets/icons/pen-icon.svg'
import { ReactComponent as CheckIcon } from 'assets/icons/check-icon.svg'
import { ReactComponent as AddIcon } from 'assets/icons/add-icon.svg'
import { Box } from 'components/Box'
import { Table } from 'components/Table'
import { usePersonalAccountConnectedPhonesStyles } from './styles'
import { getConnectedPhoneTypeLabel } from 'config/connectedPhoneType'
import { getOwnershipTypeLabel } from 'config/ownershipType'
import { getBlockReasonTypeLabel } from 'config/blockReasonType'
import { truncate } from 'utils/truncate'

export interface Account {
  id: string
  phone: Phone
  type: ConnectedPhoneType
  stopList: boolean
  ownership: OwnershipType
  reason: BlockReasonType
  comment: string
}

interface PersonalAccountConnectedAccountsProps {
  className?: string
  accounts: Account[]
  onEdit: (account: Account) => void
  onAdd: () => void
  disableAddButton?: boolean
}

export const PersonalAccountConnectedAccounts: React.FC<PersonalAccountConnectedAccountsProps> = ({
  className,
  accounts,
  onEdit,
  onAdd,
  disableAddButton,
}) => {
  const classes = usePersonalAccountConnectedPhonesStyles()

  return (
    <Box className={className}>
      <div className={classes.header}>
        <p className={classes.title}>Зв’язані з о/р номери телефонів</p>
        <PrimaryButton disabled={disableAddButton} onClick={() => onAdd()}>
          <AddIcon />
          &nbsp;Додати номер
        </PrimaryButton>
      </div>

      <Table<Account>
        showHeader
        data={accounts}
        className={classes.table}
        columns={[
          { id: 'phone', label: 'Номер*', width: '15%' },
          { id: 'type', className: classes.tdCenter, label: 'Тип*', width: '5%', render: getConnectedPhoneTypeLabel },
          {
            id: 'ownership',
            className: classes.tdCenter,
            label: 'Власник*',
            width: '12%',
            render: getOwnershipTypeLabel,
          },
          {
            id: 'reason',
            className: classes.tdCenter,
            label: 'Причина',
            width: '25%',
            render: getBlockReasonTypeLabel,
          },
          { id: 'stopList', className: classes.tdCenter, label: 'Стоп лист', width: '10%', render: renderCheck },
          {
            id: 'comment',
            label: 'Коментар',
            width: '28%',
            title: (value) => value || '',
            render: (value) => truncate(value || '', 50),
          },
          {
            id: 'id',
            label: '',
            width: '5%',
            render: (_, account) => (
              <IconButton onClick={() => onEdit(account)}>
                <PenIcon />
              </IconButton>
            ),
          },
        ]}
      />
    </Box>
  )
}

const renderCheck = (value?: string) => {
  return value ? <CheckIcon /> : null
}
