import { cacher } from 'utils/rtkQueryCacheUtils'
import { api } from '../api'
import type {
  CreateLegalEntityContactRequestDto,
  CreateLegalEntityContactResponseDto,
} from './dtos/CreateLegalEntityContactDto'
import type {
  CreateNaturalPersonContactRequestDto,
  CreateNaturalPersonContactResponseDto,
} from './dtos/CreateNaturalPersonContactDto'
import type { GetContactResponseDto } from './dtos/GetContactDto'
import type {
  UpdateLegalEntityContactRequestDto,
  UpdateLegalEntityContactResponseDto,
} from './dtos/UpdateLegalEntityContactDto'
import type {
  UpdateNaturalPersonContactRequestDto,
  UpdateNaturalPersonContactResponseDto,
} from './dtos/UpdateNaturalPersonContactDto'

const contactsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLegalEntityContact: builder.query<GetContactResponseDto, string>({
      query: (id) => ({ url: `/contacts/legal-entity/${id}`, method: 'GET' }),
      providesTags: cacher.cacheByIdArg('CONTACTS'),
    }),
    getNaturalPersonContact: builder.query<GetContactResponseDto, string>({
      query: (id) => ({ url: `/contacts/natural-person/${id}`, method: 'GET' }),
      providesTags: cacher.cacheByIdArg('CONTACTS'),
    }),
    createNaturalPersonContact: builder.mutation<
      CreateNaturalPersonContactResponseDto,
      CreateNaturalPersonContactRequestDto
    >({
      query: (data) => ({ url: '/contacts/natural-person', method: 'POST', data }),
      invalidatesTags: [
        { type: 'CONTACTS', id: 'LIST' },
        { type: 'PERSONAL_ACCOUNTS', id: 'LIST' },
      ],
    }),
    createLegalEntityContact: builder.mutation<CreateLegalEntityContactResponseDto, CreateLegalEntityContactRequestDto>(
      {
        query: (data) => ({ url: '/contacts/legal-entity', method: 'POST', data }),
        invalidatesTags: [
          { type: 'CONTACTS', id: 'LIST' },
          { type: 'PERSONAL_ACCOUNTS', id: 'LIST' },
        ],
      }
    ),
    updateLegalEntityContact: builder.mutation<UpdateLegalEntityContactResponseDto, UpdateLegalEntityContactRequestDto>(
      {
        query: ({ id, ...data }) => ({ url: `/contacts/legal-entity/${id}`, method: 'PUT', data }),
        invalidatesTags: ['CONTACTS', { type: 'PERSONAL_ACCOUNTS', id: 'LIST' }],
      }
    ),
    updateNaturalPersonContact: builder.mutation<
      UpdateNaturalPersonContactResponseDto,
      UpdateNaturalPersonContactRequestDto
    >({
      query: ({ id, ...data }) => ({ url: `/contacts/natural-person/${id}`, method: 'PUT', data }),
      invalidatesTags: ['CONTACTS', { type: 'PERSONAL_ACCOUNTS', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetLegalEntityContactQuery,
  useGetNaturalPersonContactQuery,
  useCreateLegalEntityContactMutation,
  useCreateNaturalPersonContactMutation,
  useUpdateLegalEntityContactMutation,
  useUpdateNaturalPersonContactMutation,
} = contactsApi
