/* eslint-disable no-restricted-globals */
import { useNavigate } from 'react-router-dom'

export const useGoBackOrClose = () => {
  const navigate = useNavigate()

  const goBackOrClose = () => {
    if (history?.state?.idx === 0) {
      window.close()
    } else {
      navigate(-1)
    }
  }

  return goBackOrClose
}
