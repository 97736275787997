import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import type { AxiosRequestConfig, AxiosError } from 'axios'
import { createApi } from '@reduxjs/toolkit/query/react'

import axios from 'libs/axios'
import { cacher } from 'utils/rtkQueryCacheUtils'

export interface BaseError {
  status: number
  data: unknown
}

const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string
      method?: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
      headers?: AxiosRequestConfig['headers']
      responseType?: AxiosRequestConfig['responseType']
    },
    unknown,
    BaseError
  > =>
  async ({ url, method, data, params, headers, responseType }) => {
    try {
      const result = await axios({ url, method, data, params, headers, responseType })
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError

      const error: BaseError = {
        status: err.response?.status ?? 400,
        data: err.response?.data || err.message
      }

      return {
        error,
      }
    }
  }

export const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
  tagTypes: [
    ...cacher.defaultTags,
    'REQUESTS',
    'CONTACTS',
    'PERSONAL_ACCOUNTS',
    'PERSONAL_ACCOUNTS_METER_READINGS',
    'PERSONAL_ACCOUNTS_CONNECTED_PHONES',
  ],
})
