import React from 'react'
import clsx from 'clsx'

import { Long } from 'types'
import { useScrollToLocation } from 'hooks/useScrollToLocation'
import { usePersonalAccountHistoryStyles } from './styles'
import { PersonalAccountHistoryList } from './PersonalAccountHistoryList'

interface PersonalAccountHistoryProps {
  personalAccountId?: Long
  contactId?: Long
  className?: string
  scrollableHistory?: boolean
  rounded?: boolean
  limit?: number
}

export const PersonalAccountHistory: React.FC<PersonalAccountHistoryProps> = ({
  contactId,
  personalAccountId,
  className,
  scrollableHistory,
  rounded,
  limit,
}) => {
  useScrollToLocation()
  const classes = usePersonalAccountHistoryStyles()

  return (
    <div className={clsx(classes.container, className, { [classes.rounded]: rounded })}>
      <h2 id="history" className={classes.title}>
        Історія звернень
      </h2>
      <PersonalAccountHistoryList
        contactId={contactId}
        personalAccountId={personalAccountId}
        scrollable={scrollableHistory}
        limit={limit}
      />
    </div>
  )
}
