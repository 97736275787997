import { makeStyles } from '@fluentui/react'
import { space, borderRadius, colors } from 'styles/variables'

export const useContactPageStyles = makeStyles({
  form: {
    marginBottom: space.md,
  },
  history: {
    borderRadius: borderRadius[2],
    backgroundColor: `${colors.white} !important`,
  },
})
