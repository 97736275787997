import { makeStyles } from '@fluentui/react'
import { colors, fonts, space } from 'styles/variables'
import { flex } from 'styles/utils'

export const useTagInputStyles = makeStyles({
  tagInput: {
    marginBottom: space.xs,
  },
  inputContainer: {
    ...flex('row', 'end'),
    marginBottom: space.md,
    gap: space.xs,
  },
  input: {
    flexGrow: 1,
    paddingBottom: '0 !important',
  },
  addButton: {
    minWidth: '30px',
    width: '30px',
  },
  tagsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: space.xs,
  },
  tagLabel: {
    ...fonts.subtitle2,
    fontSize: 14,
    color: 'rgb(50, 49, 48)',
    marginBottom: 5,
    fontFamily:
      "font-family: 'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;",
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 6px',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '16px',
    borderRadius: '4px',
    color: colors.comm70,
    border: `1px solid ${colors.comm140}`,
    background: colors.comm160,
    gap: space.xxs,

    '.disabled &': {
      color: colors.grey14,
      border: `1px solid ${colors.grey86}`,
      background: colors.grey99,

      svg: {
        fill: colors.grey80,
      },
    },
  },
  tagButton: {
    padding: '0px',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    height: '12px',
  },
})
