import { makeStyles } from '@fluentui/react'

export const useTableStyles = makeStyles({
  theadTr: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    color: '#707070',
    textAlign: 'left',
    padding: '4px 0',
  },
  tbodyTr: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    color: '#242424',
    textAlign: 'left',
    padding: '4px 0',
  },
})
