import React from 'react'
import { Dropdown, PrimaryButton } from '@fluentui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import subMonths from 'date-fns/subMonths'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'

import { routeList } from 'routes'
import { SupplyType, RequestType, PersonType, CompanyType } from 'types'
import { companyTypeList, getCompanyTypeLabel } from 'config/companyType'
import { supplyTypeList, supplyTypeOptions } from 'config/supplyType'
import { requestTypeList, requestTypeOptions } from 'config/requestType'
import { personTypeList, personTypeOptions } from 'config/personType'

import { keywordSchema } from 'utils/validation'
import { Box } from 'components/Box'
import { Tabs } from 'components/Tabs'
import { TagInput } from 'components/TagInput'
import { RangeDatePicker } from 'components/RangeDatePicker'

import { useFiltersStyles } from './styles'
import { reportPageTabsNavOptions } from './config'
import { useAccount } from 'hooks/useAccount'

const formSchema = z.object({
  company: z.enum(companyTypeList).optional(),
  period: z.array(z.date().nullable()).optional(),
  supplyType: z.enum(supplyTypeList).optional(),
  requestChannelType: z.enum(requestTypeList).optional(),
  personType: z.enum(personTypeList).optional(),
  keywords: z.array(keywordSchema).optional(),
})

export type ReportPageFiltersFormState = z.infer<typeof formSchema>

interface ReportPageFiltersProps {
  onSubmit: (formValues: ReportPageFiltersFormState) => void
}

export const ReportPageFilters: React.FC<ReportPageFiltersProps> = ({ onSubmit }) => {
  const classes = useFiltersStyles()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { defaultOperatorCompanyType, supervisorCompanyTypes } = useAccount()

  const supervisorCompanyTypesOptions =
    supervisorCompanyTypes?.map((key) => ({
      key,
      text: getCompanyTypeLabel(key),
    })) ?? []

  const enhancedFormSchema = formSchema.superRefine(({ keywords, period }, refinementContext) => {
    if (pathname === routeList.reportByKeywords && !keywords?.length) {
      refinementContext.addIssue({
        code: 'custom',
        path: ['keywords'],
        message: 'Задайте хоча б одне ключове слово',
      })
    }

    if (
      [routeList.reportByTheme, routeList.reportByB2b, routeList.reportByKeywords].includes(pathname) &&
      (!period || !period[0] || !period[1])
    ) {
      refinementContext.addIssue({
        code: 'custom',
        path: ['period'],
        message: 'Задайте період',
      })
    }

    return refinementContext
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ReportPageFiltersFormState>({
    resolver: zodResolver(enhancedFormSchema),
    defaultValues: {
      company: defaultOperatorCompanyType ?? CompanyType.KYIV_ENERGY_SERVICES,
      supplyType: SupplyType.ELECTRICITY,
      requestChannelType: RequestType.INCOMING_CALL,
      personType: PersonType.LEGAL_ENTITY,
      period: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
      keywords: [],
    },
  })

  return (
    <Box className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.filtersTypeContainer}>
          <p className={classes.reportTypeLabel}>Тип звіту:</p>

          <Tabs
            className={classes.tabs}
            options={reportPageTabsNavOptions}
            value={pathname}
            onChange={(value) => navigate(value)}
          />
          <PrimaryButton type="submit">Згенерувати звіт</PrimaryButton>
        </div>
        {[routeList.reportByTheme, routeList.reportByB2b, routeList.reportByKeywords].includes(pathname) && (
          <div className={classes.fieldsContainer}>
            <Controller
              name="company"
              control={control}
              render={(props) => (
                <Dropdown
                  onChange={(e, option) => props.field.onChange(option?.key)}
                  selectedKey={props.field.value}
                  errorMessage={errors[props.field?.name]?.message as string}
                  label="Компанія постачальник"
                  placeholder="Компанія постачальник"
                  options={supervisorCompanyTypesOptions}
                  className={classes.company}
                />
              )}
            />
            <Controller
              name="period"
              control={control}
              render={(props) => (
                <RangeDatePicker
                  label="Період"
                  placeholderText="Оберіть період"
                  onChange={(value) => props.field.onChange(value)}
                  startDate={props.field.value?.[0]}
                  endDate={props.field.value?.[1]}
                  className={classes.period}
                  errorMessage={errors[props.field?.name]?.message as string}
                />
              )}
            />
            <Controller
              name="supplyType"
              control={control}
              render={(props) => (
                <Dropdown
                  disabled
                  onChange={(e, option) => props.field.onChange(option?.key)}
                  selectedKey={props.field.value}
                  errorMessage={errors[props.field?.name]?.message as string}
                  label="Вид постачання"
                  placeholder="Вид постачання"
                  options={supplyTypeOptions}
                  className={classes.supplyType}
                />
              )}
            />
            <Controller
              name="requestChannelType"
              control={control}
              render={(props) => (
                <Dropdown
                  disabled
                  onChange={(e, option) => props.field.onChange(option?.key)}
                  selectedKey={props.field.value}
                  errorMessage={errors[props.field?.name]?.message as string}
                  label="Тип звернення"
                  placeholder="Тип звернення"
                  options={requestTypeOptions}
                  className={classes.requestType}
                />
              )}
            />
            {pathname === routeList.reportByKeywords && (
              <Controller
                name="keywords"
                control={control}
                render={(props) => (
                  <TagInput
                    label="Ключові слова"
                    onChange={props.field.onChange}
                    value={props.field.value}
                    placeholder="Введіть і додайте слова по черзі"
                    className={classes.keywords}
                    errorMessage={errors[props.field?.name]?.message as string}
                  />
                )}
              />
            )}
            {pathname === routeList.reportByB2b && (
              <Controller
                name="personType"
                control={control}
                render={(props) => (
                  <Dropdown
                    disabled
                    onChange={(e, option) => props.field.onChange(option?.key)}
                    selectedKey={props.field.value}
                    errorMessage={errors[props.field?.name]?.message as string}
                    label="Ознака клієнта"
                    placeholder="Ознака клієнта"
                    options={personTypeOptions}
                    className={classes.personType}
                  />
                )}
              />
            )}
          </div>
        )}

        {pathname === routeList.reportByData && (
          <p className={classes.reportByDataText}>Згенеруйте звіт та завантажте файл на комп’ютер.</p>
        )}
      </form>
    </Box>
  )
}
