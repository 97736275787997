import React from 'react'

import { getPageCount } from 'utils/pagination'
import { Helmet } from 'components/Helmet'
import { Skeleton } from 'components/Skeleton'
import { PageWrapper } from 'components/PageWrapper'
import { PersonalAccountList } from 'components/PersonalAccountList'
import { useGetEscalationQuery } from 'store/escalation'
import { withSupervisorRoleRestriction } from 'hocs/withSupervisorRoleRestriction'
import { useAccount } from 'hooks/useAccount'
import houseIcon from 'assets/icons/house-icon.svg'

import { useEscalationPageStyles } from './styles'
import { EscalationStatusType } from 'types'

const ESCALATION_LIMIT = 3

export const EscalationPage: React.FC = withSupervisorRoleRestriction(() => {
  const classes = useEscalationPageStyles()
  const [escalationRequiredPage, setEscalationRequiredPage] = React.useState(0)
  const [escalationProcessedPage, setEscalationProcessedPage] = React.useState(0)

  const { supervisorCompanyTypes } = useAccount()

  // --- queries ---
  const { data: escalationRequiredData, isFetching: isEscalationRequiredLoading } = useGetEscalationQuery({
    status: EscalationStatusType.REQUIRED,
    companyTypesIn: supervisorCompanyTypes,
    offset: escalationRequiredPage * ESCALATION_LIMIT,
    limit: ESCALATION_LIMIT,
  })
  const { data: getEscalationProcessed, isFetching: isEscalationProcessedLoading } = useGetEscalationQuery({
    status: EscalationStatusType.PROCESSED,
    companyTypesIn: supervisorCompanyTypes,
    offset: escalationProcessedPage * ESCALATION_LIMIT,
    limit: ESCALATION_LIMIT,
  })

  return (
    <PageWrapper>
      <Helmet defer={false} title="Ескалації" link={[{ rel: 'icon', type: 'image/png', href: houseIcon }]} />
      {isEscalationRequiredLoading && <Skeleton boxClassName={classes.escalationRequiredLoader} rows={6} />}
      {escalationRequiredData && (
        <>
          <h2 className={classes.title}>Потребує ескалації: {escalationRequiredData.totalCount}</h2>
          <PersonalAccountList
            page={escalationRequiredPage}
            onPageChange={setEscalationRequiredPage}
            data={escalationRequiredData?.items ?? []}
            pageCount={getPageCount(escalationRequiredData.totalCount, ESCALATION_LIMIT)}
            showCreateRequestButton={false}
          />
        </>
      )}

      {isEscalationProcessedLoading && <Skeleton rows={6} />}
      {getEscalationProcessed && (
        <>
          <h2 className={classes.title}>Відпрацьована ескалація: {getEscalationProcessed.totalCount}</h2>
          <PersonalAccountList
            page={escalationProcessedPage}
            onPageChange={setEscalationProcessedPage}
            data={getEscalationProcessed.items}
            pageCount={getPageCount(getEscalationProcessed.totalCount, ESCALATION_LIMIT)}
            showCreateRequestButton={false}
          />
        </>
      )}
    </PageWrapper>
  )
})
