import { makeStyles } from '@fluentui/react'
import { space } from 'styles/variables'

export const useContactFormStyles = makeStyles({
  form: {
    display: 'grid',
    gridTemplateColumns: '30fr 26fr',
    gap: space.md,
  },
  naturalPersonTemplate: {
    gridTemplateAreas: `
      'company personType'
      'firstName lastName'
      'middleName phoneNumber'
      'comment comment'
      'controls controls'
    `,
  },
  legalEntityTemplate: {
    gridTemplateAreas: `
      'company personType'
      'firstName lastName'
      'middleName phoneNumber'
      'companyName .'
      'comment comment'
      'controls controls'
    `,
  },
  company: {
    gridArea: 'company',
  },
  personType: {
    gridArea: 'personType',
  },
  firstName: {
    gridArea: 'firstName',
  },
  lastName: {
    gridArea: 'lastName',
  },
  middleName: {
    gridArea: 'middleName',
  },
  phoneNumber: {
    gridArea: 'phoneNumber',
  },
  comment: {
    gridArea: 'comment',
  },
  companyName: {
    gridArea: 'companyName',
  },
  controls: {
    gridArea: 'controls',
    marginTop: 0,
  },
  submitButton: {
    width: '260px',
  },
  resetButton: {
    width: '140px',
  },
})
