import clsx from 'clsx'

import { Table } from 'components/Table'

import { useReportByThemeTableStyles } from './styles'

interface ReportByKeywordsTableItem {
  id?: string
  keyWord: string
  requestsCount: number
}

export interface ReportByKeywordsTableProps {
  data: ReportByKeywordsTableItem[]
}

export const ReportByKeywordsTable: React.FC<ReportByKeywordsTableProps> = ({ data }) => {
  const classes = useReportByThemeTableStyles()

  return (
    <Table<ReportByKeywordsTableItem>
      className={clsx(classes.table, classes.keywordTable)}
      data={data}
      showHeader
      columns={[
        { id: 'keyWord', width: '25%', label: 'Кодове слово' },
        { id: 'requestsCount', width: '25%', label: 'Кількість звернень' },
      ]}
    />
  )
}
