import React from 'react'
import { IconButton } from '@fluentui/react'
import { Link, generatePath } from 'react-router-dom'

import { SupplyType } from 'types'
import { colors } from 'styles/variables'
import { ReactComponent as FireIcon } from 'assets/icons/fire-icon.svg'
import { ReactComponent as FlashIcon } from 'assets/icons/flash-icon.svg'
import { ReactComponent as EyeIcon } from 'assets/icons/eye-icon.svg'
import { ReactComponent as AddContactIcon } from 'assets/icons/add-contact-icon.svg'
import { ReactComponent as ArrowHeadIcon } from 'assets/icons/arrow-head-icon.svg'
import { ReactComponent as PersonIcon } from 'assets/icons/person-icon.svg'
import { ContactListItem, PersonalAccountsListItem } from 'store/personal-accounts'

import { usePersonalAccountListItemStyles } from './styles'
import { PersonalAccountHistory } from './PersonalAccountHistory'
import { getPersonTypeLabel } from 'config/personType'
import { routeList } from 'routes'
import { PersonalAccountsListItemIdentifierType } from 'store/personal-accounts/dtos/GetPersonalAccountsList'

const iconConfig = {
  fire: FireIcon,
  flash: FlashIcon,
  person: PersonIcon,
}

interface PersonalAccountListItemProps {
  item: PersonalAccountsListItem | ContactListItem
  showCreateRequestButton?: boolean
  createRequestLinkQueryParams?: Record<string, string>
}

export const PersonalAccountListItem: React.FC<PersonalAccountListItemProps> = ({
  item,
  showCreateRequestButton = true,
  createRequestLinkQueryParams,
}) => {
  const [collapsed, setCollapsed] = React.useState(false)
  const classes = usePersonalAccountListItemStyles()
  const Icon: React.FunctionComponent = React.useMemo(() => {
    if (item.identifierType === PersonalAccountsListItemIdentifierType.PHONE) {
      return iconConfig.person
    }

    if (item.supplyType === SupplyType.ELECTRICITY) {
      return iconConfig.flash
    }

    if (item.supplyType === SupplyType.NATURAL_GAS) {
      return iconConfig.fire
    }

    return () => null
  }, [item])

  const personFullName = item.fullName
  const number = getPersonalAccountListItemNumber(item)
  const address = getPersonalAccountListItemAddress(item)
  const lastRequestDate = item.lastRequestDate
  const personType = getPersonTypeLabel(item.personType)
  const requestsCount = item.requestsCount
  const requestDetailsLink = getDetailsLink(item)
  const createRequestLink = showCreateRequestButton ? getCreateRequestLink(item, createRequestLinkQueryParams) : ''

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div>
          <Icon />
        </div>
        <div className={classes.col}>
          <strong className={classes.number}>{number}</strong>
          <span className={classes.personType}>{personType}</span>
        </div>
        <div className={classes.col}>
          {item.hasBlockedPhone && (
            <span className={classes.blockingReason}>
              *Для даного особового рахунку телефонний номер додано у стоп-лист
            </span>
          )}
          <strong className={classes.personFullName}>{personFullName}</strong>
          <span className={classes.address}>{address}</span>
        </div>
        <div className={classes.centered}>
          <span className={classes.requestsCount}>{requestsCount}</span>
        </div>
        <div>
          <span className={classes.requestDate}>{lastRequestDate}</span>
        </div>
        <div className={classes.controls}>
          {showCreateRequestButton && (
            <IconButton style={{ background: colors.comm80 }}>
              <Link to={createRequestLink}>
                <AddContactIcon />
              </Link>
            </IconButton>
          )}
          {requestDetailsLink && (
            <IconButton style={{ background: colors.comm160 }}>
              <Link to={requestDetailsLink}>
                <EyeIcon />
              </Link>
            </IconButton>
          )}
          <IconButton
            disabled={requestsCount === 0}
            style={{ transform: collapsed ? 'rotate(180deg)' : '' }}
            onClick={() => setCollapsed((prev) => !prev)}
          >
            <ArrowHeadIcon />
          </IconButton>
        </div>
      </div>
      {collapsed && (
        <PersonalAccountHistory
          {...(item.identifierType === PersonalAccountsListItemIdentifierType.PHONE && {
            contactId: item.id,
          })}
          {...(item.identifierType === PersonalAccountsListItemIdentifierType.PERSONAL_ACCOUNT && {
            personalAccountId: item.id,
          })}
        />
      )}
    </div>
  )
}

const getPersonalAccountListItemNumber = (item: PersonalAccountsListItem | ContactListItem): string => {
  if (item.identifierType === PersonalAccountsListItemIdentifierType.PHONE) {
    return 'Контакт'
  }

  return item.number
}

const getPersonalAccountListItemAddress = (item: PersonalAccountsListItem | ContactListItem): string => {
  if (item.identifierType === PersonalAccountsListItemIdentifierType.PHONE) {
    return ''
  }

  return item.address
}

const getCreateRequestLink = (
  item: PersonalAccountsListItem | ContactListItem,
  queryParams?: Record<string, string>
) => {
  const searchParams = queryParams ? '?' + new URLSearchParams(queryParams).toString() : ''
  if (item.identifierType === PersonalAccountsListItemIdentifierType.PHONE) {
    return generatePath(routeList.contactRequest, { id: item.id ?? '' }) + searchParams
  }

  return generatePath(routeList.personalAccountRequest, { id: item.id ?? '' }) + searchParams
}

const getDetailsLink = (item: PersonalAccountsListItem | ContactListItem) => {
  if (item.identifierType === PersonalAccountsListItemIdentifierType.PHONE) {
    return generatePath(routeList.contact, { id: item.id ?? '' })
  }

  return generatePath(routeList.personalAccount, { id: item.id ?? '' })
}
