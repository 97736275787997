import { Link } from 'react-router-dom'
import { routeList } from 'routes/routeList'
import { useAccount } from 'hooks/useAccount'
import { useNavBarStyles } from './styles'

export const NavBar = () => {
  const { isSupervisor, user } = useAccount()
  const classes = useNavBarStyles()

  return (
    <nav className={classes.nav}>
      <ul className={classes.list}>
        {[
          { title: 'Головна', route: routeList.main, show: true },
          { title: 'Ескалація', route: routeList.escalation, show: isSupervisor },
          { title: 'Звіти', route: routeList.reportByTheme, show: isSupervisor },
        ]
          .filter((item) => item.show)
          .map((item) => {
            return (
              <li key={item.route} className={classes.listItem}>
                <Link to={item.route} className={classes.link}>
                  {item.title}
                </Link>
              </li>
            )
          })}
      </ul>

      <p>{user?.email ?? ''}</p>
    </nav>
  )
}
