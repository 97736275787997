export const routeList = {
  main: '/',
  call: '/inbound-call',
  // personal accounts
  personalAccount: '/personal-account/:id',
  personalAccountRequest: '/personal-account/:id/request/:requestId?',
  // contacts
  contact: '/contact/:id',
  contactRequest: '/contact/:id/request/:requestId?',
  // reports
  reportByData: '/report/by-data',
  reportByTheme: '/report/by-theme',
  reportByKeywords: '/report/by-keywords',
  reportByB2b: '/report/by-b2b',
  // escalations
  escalation: '/escalation',
  // general
  noAccess: '/no-access',
}
