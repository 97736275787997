import { makeStyles } from '@fluentui/react'

export const useRequestDetailsStyles = makeStyles({
  title: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28px',
    marginBottom: '8px',
  },
  list: {
    listStyle: 'none',
    margin: '0',
    padding: '0',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px 16px',

    li: {
      display: 'inline-flex',
      flexDirection: 'column',
    },
  },
})
