import { makeStyles } from '@fluentui/react'
import { colors, fonts, space } from 'styles/variables'

export const useEscalationPageStyles = makeStyles({
  title: {
    ...fonts.title2,
    color: colors.grey14,
    marginTop: '0px',
    marginBottom: space.md,
  },
  escalationRequiredLoader: {
    marginBottom: space.md,
  },
})
