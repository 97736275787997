import React from 'react'
import { PrimaryButton } from '@fluentui/react'

import { Box } from 'components/Box'
import { ReactComponent as FloppyDiscIcon } from 'assets/icons/floppy-disk-icon.svg'

import { useReportResultLayoutStyles } from './styles'

interface ReportResultLayoutProps {
  title: string
  onDownload: () => void
  children?: React.ReactNode
}

export const ReportResultLayout: React.FC<ReportResultLayoutProps> = ({ title, onDownload, children }) => {
  const classes = useReportResultLayoutStyles()

  return (
    <Box>
      <div className={classes.headerContainer}>
        <h2 className={classes.title}>{title}</h2>
        <PrimaryButton onClick={onDownload}>
          <FloppyDiscIcon />
          &nbsp; Завантажити
        </PrimaryButton>
      </div>
      {children && <div className={classes.children}>{children}</div>}
    </Box>
  )
}
