import { useRoutes } from 'react-router-dom'

import { MainPage } from 'pages/MainPage'
import { IncomingCallPage } from 'pages/IncomingCallPage'
import { PersonalAccountRequestPage } from 'pages/PersonalAccountRequestPage'
import { NotFoundPage } from 'pages/NotFoundPage'
import { PersonalAccountPage } from 'pages/PersonalAccountPage'
import { EscalationPage } from 'pages/EscalationPage'
import { ContactPage } from 'pages/ContactPage'
import { ContactRequestPage } from 'pages/ContactRequestPage'
import { NoAccessPage } from 'pages/NoAccessPage'
import { ReportsByKeywordsPage } from 'pages/ReportsByKeywordsPage'
import { ReportsByThemePage } from 'pages/ReportsByThemePage'
import { ReportsByB2bPage } from 'pages/ReportsByB2bPage'
// import { ReportsByData } from 'pages/ReportsByDataPage'

import { routeList } from './routeList'

export const Router = () => {
  const routes = useRoutes([
    { path: routeList.main, element: <MainPage /> },
    { path: routeList.call, element: <IncomingCallPage /> },

    // personal account
    { path: routeList.personalAccount, element: <PersonalAccountPage /> },
    { path: routeList.personalAccountRequest, element: <PersonalAccountRequestPage /> },

    // contact
    { path: routeList.contact, element: <ContactPage /> },
    { path: routeList.contactRequest, element: <ContactRequestPage /> },

    // reports
    // { path: routeList.reportByData, element: <ReportsByData /> },
    { path: routeList.reportByB2b, element: <ReportsByB2bPage /> },
    { path: routeList.reportByTheme, element: <ReportsByThemePage /> },
    { path: routeList.reportByKeywords, element: <ReportsByKeywordsPage /> },

    // escalation
    { path: routeList.escalation, element: <EscalationPage /> },

    // general
    { path: routeList.noAccess, element: <NoAccessPage /> },
    { path: '*', element: <NotFoundPage /> },
  ])

  return routes
}
