import { makeStyles } from '@fluentui/react'
import { borderRadius, colors, space } from 'styles/variables'

export const useBoxStyles = makeStyles({
  container: {
    padding: space.xl,
    borderRadius: borderRadius[2],
    backgroundColor: colors.white,
  },
})
