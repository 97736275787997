import type { AccountDetailsFormState } from 'components/AccountDetailsModal'
import type { Account } from 'components/PersonalAccountConnectedPhones'

export const mapAccountToEdit = (account: Account): AccountDetailsFormState => {
  return {
    phone: String(account.phone),
    type: account.type,
    ownership: account.ownership,
    reason: account.reason,
    stopList: account.stopList,
    comment: account.comment,
  }
}
