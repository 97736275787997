import { MeterType } from 'types'
import {
  PersonalAccountMeterReading,
  OneZoneMeterReading,
  TwoZoneMeterReading,
  ThreeZoneMeterReading,
} from 'store/personal-accounts'

type MeterReadingGroup =
  | {
      type: MeterType.ONE_ZONE
      readings: OneZoneMeterReading[]
    }
  | {
      type: MeterType.TWO_ZONE
      readings: TwoZoneMeterReading[]
    }
  | {
      type: MeterType.THREE_ZONE
      readings: ThreeZoneMeterReading[]
    }

/**
 * @example
 * const arr = [
 *   { type: '1-zone', date: new Date('2021-01-01'), consumption: '100' },
 *   { type: '1-zone', date: new Date('2021-01-02'), consumption: '200' },
 *   { type: '2-zone', date: new Date('2021-01-01'), dayZoneConsumption: '100', nightZoneConsumption: '200' },
 *   { type: '2-zone', date: new Date('2021-01-02'), dayZoneConsumption: '300', nightZoneConsumption: '400' },
 *   { type: '3-zone', date: new Date('2021-01-01'), peakZoneConsumption: 100, halfPeakZoneConsumption: 200, nightZoneConsumption: 300 },
 *   { type: '3-zone', date: new Date('2021-01-02'), peakZoneConsumption: 400, halfPeakZoneConsumption: 500, nightZoneConsumption: 600 },
 * ]
 *
 * const result = groupMeterReading(arr) // => [
 * {
 *   type: '1-zone',
 *   readings: [
 *     { type: '1-zone', date: new Date('2021-01-01'), consumption: '100' },
 *     { type: '1-zone', date: new Date('2021-01-02'), consumption: '200' },
 *   ]
 * },
 * {
 *   type: '2-zone',
 *   readings: [
 *     { type: '2-zone', date: new Date('2021-01-01'), dayZoneConsumption: '100', nightZoneConsumption: '200' },
 *     { type: '2-zone', date: new Date('2021-01-02'), dayZoneConsumption: '300', nightZoneConsumption: '400' },
 *   ]
 * },
 * {
 *   type: '3-zone',
 *   readings: [
 *     { type: '3-zone', date: new Date('2021-01-01'), peakZoneConsumption: 100, halfPeakZoneConsumption: 200, nightZoneConsumption: 300 },
 *     { type: '3-zone', date: new Date('2021-01-02'), peakZoneConsumption: 400, halfPeakZoneConsumption: 500, nightZoneConsumption: 600 },
 *   ]
 * }
 * ]
 */
export function groupMeterReading(arr: PersonalAccountMeterReading[]): MeterReadingGroup[] {
  return arr.reduce<MeterReadingGroup[]>((acc, currentReading) => {
    const lastGroup = acc[acc.length - 1]

    if (lastGroup?.type === currentReading.type) {
      lastGroup.readings.push(currentReading as any)
    } else {
      acc.push({
        type: currentReading.type,
        readings: [currentReading as any],
      })
    }

    return acc
  }, [])
}
