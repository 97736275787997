// 0636416787 -> 063 641 67 87
// 063 641 67 87 -> 063 641 67 87
// 380 63 641 67 87 -> 063 641 67 87
// 380636416787 -> 063 641 67 87
// +380636416787 -> 063 641 67 87
// +380 63 641 67 87 -> 063 641 67 87
export const formatPhone = (phone: string | number): string => {
  // Keep only digits and check number length
  const digits = String(phone).replace(/\D/g, '')
  const numberLength = digits.length
  let phonePart = digits

  // If phone starts with '380', remove '38'
  if (digits.startsWith('380') && numberLength >= 10) {
    phonePart = digits.substr(2)
  }

  // Insert spaces according to the pattern 063 641 67 87
  return phonePart.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4')
}

// 0636416787 -> 380636416787
// 063 641 67 87 -> 380636416787
// 380 63 641 67 87 -> 380636416787
// 380636416787 -> 380636416787
// +380636416787 -> 380636416787
// +380 63 641 67 87 -> 380636416787
export const mapPhoneToBackend = (phone: string | number): number => {
  let phoneString = String(phone).replace(/\D/g, '') // Remove non-digit characters
  if (phoneString.startsWith('+')) {
    phoneString = phoneString.slice(1) // Remove leading "+"
  }
  if (phoneString.startsWith('0')) {
    phoneString = phoneString.slice(1) // Remove leading "0"
  }
  if (!phoneString.startsWith('380')) {
    phoneString = `380${phoneString}` // Prepend "380"
  }
  return Number(phoneString)
}

export const extractPhoneNumbers = (phone: string | number): string => {
  return String(phone).replace(/\D/g, '')
}

export const isMobilePhone = (phone: string | number): boolean => {
  return [
    '38039',
    '38050',
    '38063',
    '38066',
    '38067',
    '38068',
    '38069',
    '38073',
    '38075',
    '38076',
    '38077',
    '38090',
    '38091',
    '38093',
    '38095',
    '38096',
    '38097',
    '38098',
    '38099',
  ].some((prefix) => String(phone).startsWith(prefix))
}
