import { cacher } from 'utils/rtkQueryCacheUtils'
import { api } from 'store/api'
import type { GetRequestResponseDto } from './dtos/GetRequestDto'
import type { CreateRequestRequestDto, CreateRequestResponseDto } from './dtos/CreateRequestDto'
import type { UpdateRequestRequestDto, UpdateRequestResponseDto } from './dtos/UpdateRequestDto'
import type { GetRequestsListDto, GetRequestsListResponseDto } from './dtos/GetRequestsListDto'

const requestsEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    getRequests: builder.query<GetRequestsListResponseDto, GetRequestsListDto>({
      query: (params) => ({ url: '/requests', params, method: 'GET' }),
      providesTags: cacher.providesList('REQUESTS'),
    }),
    getRequest: builder.query<GetRequestResponseDto, string>({
      query: (id) => ({ url: `/requests/${id}`, method: 'GET' }),
      providesTags: cacher.cacheByIdArg('REQUESTS'),
    }),
    createRequest: builder.mutation<CreateRequestResponseDto, CreateRequestRequestDto>({
      query: (data) => ({ url: '/requests', data, method: 'POST' }),
      invalidatesTags: () => [
        ...cacher.invalidatesList('REQUESTS')(),
        ...cacher.invalidatesList('PERSONAL_ACCOUNTS')(),
      ],
    }),
    updateRequest: builder.mutation<UpdateRequestResponseDto, UpdateRequestRequestDto>({
      query: ({ id, ...data }) => ({ url: `/requests/${id}`, data, method: 'PUT' }),
      invalidatesTags: (result, error, arg) => [
        ...cacher.cacheByIdArgProperty('REQUESTS')(result, error, arg),
        ...cacher.cacheByIdArgProperty('PERSONAL_ACCOUNTS')(result, error, arg),
      ],
    }),
    getNextNumber: builder.query<string, void>({
      query: () => ({ url: '/requests/next-number', method: 'PUT' }),
      forceRefetch: () => true,
    }),
  }),
})

export const {
  useGetRequestQuery,
  useCreateRequestMutation,
  useUpdateRequestMutation,
  useGetNextNumberQuery,
  useLazyGetRequestsQuery,
} = requestsEndpoints
