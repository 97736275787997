import React from 'react'
import clsx from 'clsx'
import { Link, generatePath } from 'react-router-dom'

import { Long } from 'types'
import { routeList } from 'routes'
import { Skeleton } from 'components/Skeleton'
import { useScroll } from 'hooks/useScroll'
import { useLazyGetRequestsQuery, RequestListItem } from 'store/requests'
import { getRequestReasonTypeLabel } from 'config/requestReasonType'
import { truncate } from 'utils/truncate'

import { usePersonalAccountHistoryListStyles } from './styles'

interface PersonalAccountHistoryListProps {
  limit?: number
  className?: string
  personalAccountId?: Long
  contactId?: Long
  scrollable?: boolean
  rounded?: boolean
}

export const PersonalAccountHistoryList: React.FC<PersonalAccountHistoryListProps> = ({
  limit = 3,
  className,
  personalAccountId,
  contactId,
  scrollable = false,
}) => {
  const classes = usePersonalAccountHistoryListStyles()
  const childRef = React.useRef<HTMLDivElement>(null)

  const [page, setPage] = React.useState(0)
  const [items, setItems] = React.useState<RequestListItem[]>([])
  const [totalCount, setTotalCount] = React.useState<number>()
  const [fetchRequests, { isFetching }] = useLazyGetRequestsQuery()

  const left = totalCount ? totalCount - page * limit : 0
  const hasMore = page === 0 || left > 0
  const shouldRunOnScroll = scrollable || page === 0

  useScroll({
    childRef,
    hasMore,
    deps: [page],
    callback: async () => {
      if (!isFetching && shouldRunOnScroll) {
        const { items, totalCount } = await fetchRequests({
          contactId: contactId,
          personalAccountId: personalAccountId,
          limit,
          offset: page * limit,
        }).unwrap()
        setItems((prev) => [...prev, ...items])
        setTotalCount(totalCount)
        setPage((prev) => prev + 1)
      }
    },
  })

  if (totalCount === 0) {
    return <p>Звернень не знайдено</p>
  }

  return (
    <div className={className}>
      <div
        className={clsx(classes.list, {
          [classes.scrollable]: scrollable,
        })}
      >
        <div className={clsx(classes.row, classes.headerItem)}>
          <p>№</p>
          <p>Дата</p>
          <p>Тема звернення</p>
          <p>Причина</p>
          <p>Агент</p>
          <p>Коментар</p>
        </div>
        {items.map((item) => (
          <div key={item.id} className={clsx(classes.row, classes.rowItem)}>
            <Link className={classes.link} to={getPath(item, { contactId, personalAccountId })} target="_blank">
              {item.number}
            </Link>
            <span>{item.date}</span>
            <span>{item.theme}</span>
            <span>{getRequestReasonTypeLabel(item.reason)}</span>
            <span className={classes.agent}>{item.agent}</span>
            <span title={item.comment}>{truncate(item.comment, 50)}</span>
          </div>
        ))}

        {hasMore && shouldRunOnScroll && (
          <div ref={childRef}>
            <Skeleton box={false} rows={3} />
          </div>
        )}
      </div>
      {!scrollable && left > 0 && (
        <Link to={getLinkToHistory({ contactId, personalAccountId })} className={classes.left}>
          Ще {left} звернень
        </Link>
      )}
    </div>
  )
}

const getPath = (item: RequestListItem, params: { contactId?: Long; personalAccountId?: Long }) => {
  if (params.contactId) {
    return generatePath(routeList.contactRequest, {
      id: params.contactId,
      requestId: item.id ?? '',
    })
  }

  if (params.personalAccountId) {
    return generatePath(routeList.personalAccountRequest, {
      id: params.personalAccountId,
      requestId: item.id ?? '',
    })
  }

  return ''
}

const getLinkToHistory = (params: { contactId?: Long; personalAccountId?: Long }) => {
  if (params.contactId) {
    return generatePath(routeList.contact, { id: params.contactId }) + '#history'
  }

  if (params.personalAccountId) {
    return generatePath(routeList.personalAccount, { id: params.personalAccountId }) + '#history'
  }

  return ''
}
