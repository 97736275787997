// --- primitives ---
export type Long = number
export type PersonalAccountNumber = string
export type DateTimeString = string
export type FullName = string
export type TaxNumber = string
export type Email = string
export type Address = string
export type EisCode = string
export type Phone = number
export type DictionaryId = number

// --- enums ---
export enum SupplyType {
  ELECTRICITY = 'ELECTRICITY',
  NATURAL_GAS = 'NATURAL_GAS',
}

export enum PersonType {
  UNKNOWN = 'UNKNOWN',
  NATURAL_PERSON = 'NATURAL_PERSON',
  LEGAL_ENTITY = 'LEGAL_ENTITY',
}

export enum CompanyType {
  // NONE = 'NONE',
  KYIV_ENERGY_SERVICES = 'KYIV_ENERGY_SERVICES',
  DNIPRO_ENERGY_SERVICES = 'DNIPRO_ENERGY_SERVICES',
  DONETSK_ENERGY_SERVICES = 'DONETSK_ENERGY_SERVICES',
  YASNO = 'YASNO',
}

export enum RequestType {
  INCOMING_CALL = 'INCOMING_CALL',
  OUTGOING_CALL = 'OUTGOING_CALL',
  TELEGRAM = 'TELEGRAM',
  VIBER = 'VIBER',
  CHAT_WIDGET = 'CHAT_WIDGET',
}

export enum RequestReasonType {
  CONSULTATION = 'CONSULTATION',
  INTERMEDIATE_RESPONSE = 'INTERMEDIATE_RESPONSE',
  COMPLIANT = 'COMPLIANT',
  APPRECIATION = 'APPRECIATION',
  STATEMENT = 'STATEMENT',
  PROPOSAL = 'PROPOSAL',
}

export enum EscalationStatusType {
  NOT_REQUIRED = 'NOT_REQUIRED',
  REQUIRED = 'REQUIRED',
  PROCESSED = 'PROCESSED',
}

export enum ContractType {
  UNIVERSAL = 'UNIVERSAL',
  UNREGULATED = 'UNREGULATED',
}

export enum PersonalAccountConnectionStatus {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

export enum PersonalAccountStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum UserCompanyRole {
  ADMIN = 'Admin',
  OPERATOR = 'Operator',
  SUPERVISOR = 'Supervisor',
}

export enum ConnectedPhoneType {
  MOBILE = 'MOBILE',
  HOME = 'HOME',
  OTHER = 'OTHER',
}

export enum MeterType {
  ONE_ZONE = 'ONE_ZONE',
  TWO_ZONE = 'TWO_ZONE',
  THREE_ZONE = 'THREE_ZONE',
}

export enum OwnershipType {
  OWNER = 'OWNER',
  SECOND_PERSON = 'SECOND_PERSON',
  THIRD_PERSON = 'THIRD_PERSON',
  OTHER = 'OTHER',
}

export enum BlockReasonType {
  ZSU = 'ZSU',
  LEFT = 'LEFT',
  OTHER_PERSON = 'OTHER_PERSON',
  WORK = 'WORK',
  GPZ = 'GPZ',
  NKT = 'NKT',
  IND = 'IND',
}

export interface Option<T = string> {
  key: T
  text: string
}

export interface RequestPaginationDto {
  offset: number
  limit: number
}

export interface Pagination<T> {
  totalCount: number
  items: T[]
}

export interface User {
  id: string
  fullName: string
  email: string
  type: Array<{
    type: CompanyType
    role: UserCompanyRole
  }>
}
