import React from 'react'

import { ReportResultLayout } from 'components/ReportResultLayout'
import {ReportByKeywordsTable, ReportByKeywordsTableProps} from 'components/ReportTables'

interface ReportByKeywordProps extends ReportByKeywordsTableProps {
  onDownload: () => void
}

export const ReportByKeywords: React.FC<ReportByKeywordProps> = ({ onDownload, data }) => {
  return (
    <ReportResultLayout
      onDownload={onDownload}
      title="Інформація щодо кількості прийнятих звернень кол-центром електропостачальника за кодовим словом"
    >
      <ReportByKeywordsTable data={data} />
    </ReportResultLayout>
  )
}
