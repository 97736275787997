import React from 'react'
import { PrimaryButton } from '@fluentui/react'
import { useSearchParams } from 'react-router-dom'

import { Helmet } from 'components/Helmet'
import { LIMIT } from 'config/pagination'
import { getCompanyTypeLabel } from 'config/companyType'
import { Skeleton } from 'components/Skeleton'
import { PageWrapper } from 'components/PageWrapper'
import { PersonalAccountList } from 'components/PersonalAccountList'
import { ReactComponent as AddIcon } from 'assets/icons/add-icon.svg'
import phoneIncomingCallBlackIcon from 'assets/icons/phone-incoming-call-black-icon.svg'
import { useGetPersonalAccountsListQuery } from 'store/personal-accounts'
import { useCreateLegalEntityContactMutation, useCreateNaturalPersonContactMutation } from 'store/contacts'
import { NewContactModal } from 'components/NewContactModal'
import { type ContactFormState } from 'components/ContactForm'
import {
  ALL_COMPANY_KEY,
  ALL_PERSON_TYPE_KEY,
  PersonalAccountFilters,
  PersonalAccountFiltersFormState,
} from 'components/PersonalAccountFilters'
import { mapLegalEntityContactToSubmit, mapNaturalPersonContactToSubmit } from 'utils/mappers/mapContactToSubmit'
import { Error } from 'components/Error'

import { IncomingCallDetails } from './components/IncomingCallDetails'
import { useIncomingCallPageStyles } from './styles'
import { PersonType } from 'types'
import { getPageCount } from 'utils/pagination'
import { filterQueryParams } from 'utils/filterQueryParams'
import { useGetCompaniesDictionaryQuery } from 'store/dictionaries'
import { extractPhoneNumbers, mapPhoneToBackend } from 'utils/phone'
import { isEntryDuplicateException } from 'utils/errors'

/**
 * /?ani=0645416787&dn=0645416787&callId=12345A&company=DNEP&agentLogin=kulebamb&team=LAB.TEAM&ext=380975712345
 * ext - номер телефону агента, який прйняв дзвінок
 * team - команда, в якій знаходиться агент
 */
export const IncomingCallPage = () => {
  const classes = useIncomingCallPageStyles()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('callid')
  const phone = searchParams.get('ani')!
  const company = searchParams.get('company')!
  const contactCenterPhone = searchParams.get('dn')!
  const agentPhone = searchParams.get('ext')! || ''
  const team = searchParams.get('team')! || ''

  const { data: companiesDictionary, isLoading: companiesDictionaryLoading } = useGetCompaniesDictionaryQuery()
  const internalCompany = companiesDictionary?.find((item) => item.ciscoId === company)?.id

  // --- state ---
  const [page, setPage] = React.useState(0)
  const [formState, setFormState] = React.useState<Partial<PersonalAccountFiltersFormState>>({
    phoneNumber: String(mapPhoneToBackend(phone)),
  })
  const [newContactModalVisible, toggleNewContactModal] = React.useReducer((prev) => !prev, false)
  const defaultValues = React.useMemo(() => {
    return {
      phoneNumber: String(mapPhoneToBackend(phone)),
      company: internalCompany,
    }
  }, [internalCompany, phone])

  // --- queries ---
  const {
    data: personalAccountsListData,
    isFetching,
    isSuccess,
    error,
  } = useGetPersonalAccountsListQuery(
    filterQueryParams({
      offset: page * LIMIT,
      limit: LIMIT,
      ...formState,
      personType: formState?.personType === ALL_PERSON_TYPE_KEY ? undefined : formState?.personType,
      company: formState?.company === ALL_COMPANY_KEY ? undefined : formState?.company,
    }),
    { skip: !internalCompany }
  )

  // --- mutations ---
  const [createLegalEntityContact] = useCreateLegalEntityContactMutation()
  const [createNaturalPersonContact] = useCreateNaturalPersonContactMutation()

  // --- handlers ---
  const handleNewContactModalSubmit = async (formState: ContactFormState) => {
    try {
      if (formState.personType === PersonType.LEGAL_ENTITY) {
        await createLegalEntityContact(mapLegalEntityContactToSubmit(formState)).unwrap()
      }

      if (formState.personType === PersonType.NATURAL_PERSON) {
        await createNaturalPersonContact(mapNaturalPersonContactToSubmit(formState)).unwrap()
      }

      toggleNewContactModal()
    } catch (e) {
      if (isEntryDuplicateException(e)) {
        throw {
          phone: 'Номер вже додано',
        }
      }
      alert('Помилка при збереженні')
    }
  }

  const handleSubmitPersonalAccountFilters = (formState: PersonalAccountFiltersFormState) => {
    setFormState({
      ...formState,
      ...(formState.phoneNumber && { phoneNumber: extractPhoneNumbers(formState.phoneNumber) }),
    })
    setPage(0)
  }

  const handleResetPersonalAccountFilters = (formState: PersonalAccountFiltersFormState) => {
    setFormState({
      ...formState,
      ...(formState.phoneNumber && { phoneNumber: extractPhoneNumbers(formState.phoneNumber) }),
    })
    setPage(0)
  }

  // --- effects ---
  React.useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      company: internalCompany,
    }))
  }, [internalCompany])

  if (companiesDictionaryLoading) {
    return <Skeleton rows={2} />
  }

  return (
    <>
      <Helmet title="Вхідний дзвінок" link={[{ rel: 'icon', type: 'image/png', href: phoneIncomingCallBlackIcon }]} />

      <PageWrapper>
        <IncomingCallDetails
          incomingPhoneNumber={extractPhoneNumbers(phone)}
          contactPhoneNumber={extractPhoneNumbers(contactCenterPhone)}
          companyName={getCompanyTypeLabel(internalCompany)}
          agentPhone={agentPhone}
          team={team}
        />
        <PersonalAccountFilters
          showResetFiltersButton
          disableCompanySelect
          onSubmit={handleSubmitPersonalAccountFilters}
          onReset={handleResetPersonalAccountFilters}
          defaultValues={defaultValues}
        />
        {error && <Error error={error} />}
        {isFetching && <Skeleton rows={6} />}
        {!isFetching && isSuccess && (
          <>
            <div className={classes.header}>
              <h1 className={classes.title}>Знайдено особових рахунків: {personalAccountsListData.totalCount}</h1>
              <PrimaryButton onClick={toggleNewContactModal}>
                <AddIcon />
                &nbsp;Новий контакт
              </PrimaryButton>
            </div>
            <PersonalAccountList
              page={page}
              onPageChange={setPage}
              data={personalAccountsListData.items}
              pageCount={getPageCount(personalAccountsListData.totalCount, LIMIT)}
              createRequestLinkQueryParams={{
                callid: id!,
                ani: phone!,
                company: company!,
                dn: contactCenterPhone!,
                ext: agentPhone!,
                team: team!,
              }}
            />
          </>
        )}
      </PageWrapper>

      <NewContactModal
        defaultValues={{
          company: internalCompany,
          phoneNumber: String(mapPhoneToBackend(phone)),
        }}
        visible={newContactModalVisible}
        onClose={toggleNewContactModal}
        onSubmit={handleNewContactModalSubmit}
        onCancel={toggleNewContactModal}
        controlProps={{
          company: {
            disabled: true,
          },
        }}
      />
    </>
  )
}
