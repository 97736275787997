import React from 'react'
import { Helmet } from 'components/Helmet'
import { useLazyGetReportsByKeywordsQuery, useDownloadReportsByKeywordsMutation } from 'store/reports'
import { Skeleton } from 'components/Skeleton'
import { PageWrapper } from 'components/PageWrapper'
import { ReportPageFilters, ReportPageFiltersFormState } from 'components/ReportPageFilters'
import { withSupervisorRoleRestriction } from 'hocs/withSupervisorRoleRestriction'
import reportIcon from 'assets/icons/report-icon.svg'
import { mapReportByKeywordsToSubmit } from 'utils/mappers/mapReportToSubmit'

import { ReportByKeywords } from './components/ReportByKeywords'

export const ReportsByKeywordsPage = withSupervisorRoleRestriction(() => {
  const [formState, setFormState] = React.useState<ReportPageFiltersFormState>()
  const [generateReport, { data, isLoading }] = useLazyGetReportsByKeywordsQuery()
  const [downloadReport] = useDownloadReportsByKeywordsMutation()

  const handleSubmit = async (formState: ReportPageFiltersFormState) => {
    await generateReport(mapReportByKeywordsToSubmit(formState))
    setFormState(formState)
  }

  const handleDownload = async () => {
    if (!formState) return
    await downloadReport(mapReportByKeywordsToSubmit(formState))
  }

  return (
    <PageWrapper>
      <Helmet title="Звіти" link={[{ rel: 'icon', type: 'image/png', href: reportIcon }]} />
      <ReportPageFilters onSubmit={handleSubmit} />
      {isLoading && <Skeleton rows={6} />}
      {data && (
        <ReportByKeywords
          data={[
            ...data.items,
            {
              keyWord: 'Всього',
              requestsCount: data.total.requestsCount,
            },
          ]}
          onDownload={handleDownload}
        />
      )}
    </PageWrapper>
  )
})
