import React from 'react'
import format from 'date-fns/format'

import { CompanyType, Phone } from 'types'
import { getCompanyTypeLabel } from 'config/companyType'

import { useRequestDetailsStyles } from './styles'

interface RequestDetailsProps {
  id?: string
  className?: string
  number?: string
  createdAt?: string
  incomePhoneNumber?: Phone
  company?: CompanyType
  contactCenterPhoneNumber?: Phone
  authorFullName?: string
  editorFullName?: string
  agentPhone?: string
  team?: string
}

export const RequestDetails: React.FC<RequestDetailsProps> = ({
  className,
  id,
  number,
  createdAt,
  incomePhoneNumber,
  company,
  contactCenterPhoneNumber,
  authorFullName,
  editorFullName,
  agentPhone,
  team,
}) => {
  const classes = useRequestDetailsStyles()

  return (
    <div className={className}>
      <h2 className={classes.title}>Деталі звернення</h2>
      <ul className={classes.list}>
        <li>
          <strong>№ звернення</strong>
          <span>{number ?? '-'}</span>
        </li>
        <li>
          <strong>Дата та час</strong>
          <span>{createdAt ? format(new Date(createdAt), 'dd.MM.yy HH:mm:ss') : '-'}</span>
        </li>
        {authorFullName && (
          <li>
            <strong>Створив</strong>
            <span>{authorFullName}</span>
          </li>
        )}
        {editorFullName && (
          <li>
            <strong>Змінив</strong>
            <span>{editorFullName}</span>
          </li>
        )}
        <li>
          <strong>Номер з якого телефонують</strong>
          <span>{incomePhoneNumber ?? '-'}</span>
        </li>
        <li>
          <strong>Ідентифікатор дзвінка</strong>
          <span>{id || '-'}</span>
        </li>
        <li>
          <strong>Компанія</strong>
          <span>{company ? getCompanyTypeLabel(company) : '-'}</span>
        </li>
        <li>
          <strong>Контакт-центр</strong>
          <span>{contactCenterPhoneNumber ?? '-'}</span>
        </li>
        {agentPhone && (
          <li>
            <strong>Номер агента</strong>
            <span>{agentPhone}</span>
          </li>
        )}
        {team && (
          <li>
            <strong>Команда</strong>
            <span>{team}</span>
          </li>
        )}
      </ul>
    </div>
  )
}
