import React from 'react'
import { DefaultButton, DialogFooter, PrimaryButton, TextField } from '@fluentui/react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

const requiredMessage = "Це поле обов'язкове"

const generalMeterReadingFormSchema = z.object({
  consumption: z.string({ required_error: requiredMessage }),
})

export type GeneralMeterReadingFormState = z.infer<typeof generalMeterReadingFormSchema>

interface GeneralMeterReadingFormProps {
  onCancel: () => void
  onSubmit: (formValues: GeneralMeterReadingFormState) => void
}

export const GeneralMeterReadingForm: React.FC<GeneralMeterReadingFormProps> = ({ onCancel, onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<GeneralMeterReadingFormState>({
    resolver: zodResolver(generalMeterReadingFormSchema),
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="consumption"
        control={control}
        render={(props) => (
          <TextField
            {...props.field}
            errorMessage={errors?.[props.field?.name]?.message as string}
            label="Нові показання"
            placeholder="Введіть нові показання"
          />
        )}
      />
      <DialogFooter styles={{ actionsRight: { justifyContent: 'flex-start' } }}>
        <PrimaryButton text="Зберегти показання" type="submit" />
        <DefaultButton onClick={onCancel} type="reset" text="Скасувати передачу" />
      </DialogFooter>
    </form>
  )
}
