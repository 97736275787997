import React from 'react'

import { ReportResultLayout } from 'components/ReportResultLayout'
import { ReportByThemeTable, ReportByThemeTableProps } from 'components/ReportTables'

interface ReportByThemeProps extends ReportByThemeTableProps {
  onDownload: () => void
}

export const ReportByTheme: React.FC<ReportByThemeProps> = ({ onDownload, data }) => {
  return (
    <ReportResultLayout
      onDownload={onDownload}
      title="Інформація щодо кількості прийнятих звернень кол-центром електропостачальника за тематикою"
    >
      <ReportByThemeTable data={data} />
    </ReportResultLayout>
  )
}
