import { makeStyles } from '@fluentui/react'

export const useReadingFormStyles = makeStyles(() => ({
  formFields: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '8px',

    '& > *': {
      flex: 1,
    },
  },
}))
