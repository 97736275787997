import type { ContactFormState } from 'components/ContactForm'
import type { GetContactResponseDto } from 'store/contacts'
import { PersonType } from 'types'

export const mapContactToEdit = (contact: GetContactResponseDto): ContactFormState => {
  const commonFields = {
    company: contact?.company,
    phoneNumber: String(contact?.phoneNumber),
    comment: contact?.comment,
    personType: contact?.type,
  }

  if (contact.type === PersonType.LEGAL_ENTITY) {
    return {
      ...commonFields,
      firstName: contact?.companyRepresentativeFirstName,
      lastName: contact?.companyRepresentativeLastName,
      middleName: contact?.companyRepresentativeMiddleName,
      companyName: contact?.companyName,
    }
  }

  return {
    ...commonFields,
    firstName: contact?.firstName,
    lastName: contact?.lastName,
    middleName: contact?.middleName,
  }
}
