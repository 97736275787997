import axios, { AxiosRequestConfig } from 'axios'

import { env } from 'config/env'
import { msal } from 'containers/MSAuth/MSAuth'
import { storageAccessToken } from 'utils/storage'
import { routeList } from 'routes'

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error.response ? error.response.status : null

    if (status === 401) {
      try {
        const config = error.response.config
        const { accessToken } = await msal.getAccessToken()
        storageAccessToken.set(accessToken)

        return axios.request({
          ...config,
          headers: { ...config.headers, Authorization: `Bearer ${accessToken}` },
        })
      } catch (e: any) {
        if (msal.requiresInteraction(e?.errorCode)) {
          msal.acquireTokenRedirect()
          return
        }
        msal.redirectToLogin()
      }
    }

    if (status === 403) {
      try {
        if (window.location.pathname !== routeList.noAccess) {
          window.location.href = routeList.noAccess
        }
      } catch (e) {
        console.error('403', e)
      }
    }

    return Promise.reject(error)
  }
)

const req = ({ headers, ...axiosProps }: AxiosRequestConfig) => {
  const token = storageAccessToken.get()
  const auth = token ? { Authorization: `Bearer ${token}` } : null

  const basicHeaders = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    'Content-Type': 'application/json',
  }

  const baseURL = process.env.REACT_APP_MODE === 'dev' ? '/' : env.API_URL

  return axios({
    baseURL,
    headers: headers ? { ...auth, ...headers } : { ...auth, ...basicHeaders },
    paramsSerializer: {
      indexes: null,
    },
    ...axiosProps,
  })
}

export default req
