import { BlockReasonType, Option } from 'types'

const blockReasonTypeLabel: Record<BlockReasonType, string> = {
  [BlockReasonType.ZSU]: 'ЗСУ',
  [BlockReasonType.LEFT]: 'Зміна адреси проживання/колишній квартирант/колишній власник',
  [BlockReasonType.OTHER_PERSON]: 'Немає відношення до номеру',
  [BlockReasonType.WORK]: 'Номер телефону співробітника, що вносив дані',
  [BlockReasonType.GPZ]: 'У клієнта графік погашення боргу/гарантійний лист/борг попереднього власника',
  [BlockReasonType.NKT]: 'НКТ',
  [BlockReasonType.IND]: 'Індивідуальні зміни',
}

export const getBlockReasonTypeLabel = (blockReasonType?: BlockReasonType): string => {
  if (!blockReasonType) return ''
  return blockReasonTypeLabel[blockReasonType]
}

export const blockReasonTypeList = [
  BlockReasonType.ZSU,
  BlockReasonType.LEFT,
  BlockReasonType.OTHER_PERSON,
  BlockReasonType.WORK,
  BlockReasonType.GPZ,
  BlockReasonType.NKT,
  BlockReasonType.IND,
] as const

export const blockReasonTypeOptions: Option<BlockReasonType>[] = blockReasonTypeList.map((key) => ({
  key,
  text: getBlockReasonTypeLabel(key),
  title: getBlockReasonTypeLabel(key),
}))
