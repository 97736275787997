import { makeStyles } from '@fluentui/react'
import { colors, fonts } from 'styles/variables'

const rangeStartEndStyles = {
  color: `${colors.white} !important`,
  position: 'relative',
}

const dayStyles = {
  borderRadius: '50%',
  background: colors.comm80,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
}

export const useDatePickerStyles = makeStyles({
  wrapper: {
    width: '100%',
  },
  calendar: {
    border: 'none !important',

    '.react-datepicker__header': {
      background: 'none',
      border: 'none',
    },
    '.react-datepicker__day': {
      width: '36px',
      height: '36px',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 0,
      borderRadius: 0,
      color: '#3F0C12',
      ...fonts.body1,
    },
    '.react-datepicker__day-names': {
      display: 'flex',
      justifyContent: 'space-around',
      borderBottom: `1px solid #E2E5ED`,
    },
    '.react-datepicker__day-name': {
      color: colors.grey38,
    },
    '.react-datepicker__week': {
      display: 'flex',
      justifyContent: 'space-around',
    },
    '.react-datepicker__day:hover': {
      background: `${colors.comm160} !important`,
      borderRadius: 0,
    },
    '.react-datepicker__day--in-range': {
      background: colors.comm160,
      colors: colors.white,
    },
    '.react-datepicker__day--outside-month': {
      visibility: 'hidden',
    },
    '.react-datepicker__day--in-selecting-range': {
      background: `${colors.comm160} !important`,
      colors: `${colors.white} !important`,
    },
    '.react-datepicker__day--selecting-range-start': {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    },
    '.react-datepicker__day--selecting-range-end': {
      borderTopRightRadius: '50% !important',
      borderBottomRightRadius: '50% !important',
    },
    '.react-datepicker__day--range-start': {
      ...rangeStartEndStyles,
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
      '.datepicker-day': dayStyles,
    },
    '.react-datepicker__day--range-end': {
      ...rangeStartEndStyles,
      borderTopRightRadius: '50% !important',
      borderBottomRightRadius: '50% !important',
      '.datepicker-day': dayStyles,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerButton: {
    background: 'none',
    border: 'none',
    outline: 'none',
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
  },
  headerControls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerValue: {
    padding: '0 8px',
    color: '#292929',
    ...fonts.body1,
  },
})
