import { EscalationStatusType } from 'types'

const escalationStatusTypeLabels: Record<EscalationStatusType, string> = {
  [EscalationStatusType.NOT_REQUIRED]: 'Не потрібна',
  [EscalationStatusType.REQUIRED]: 'Потрібна',
  [EscalationStatusType.PROCESSED]: 'Відпрацьована',
}

export const getEscalationStatusTypeLabel = (escalationStatusType?: EscalationStatusType): string => {
  if (!escalationStatusType) return ''
  return escalationStatusTypeLabels[escalationStatusType]
}

export const escalationStatusTypeList = [
  EscalationStatusType.NOT_REQUIRED,
  EscalationStatusType.REQUIRED,
  EscalationStatusType.PROCESSED,
] as const

export const escalationStatusTypeOptions = escalationStatusTypeList.map((key) => ({
  key,
  text: getEscalationStatusTypeLabel(key),
}))
