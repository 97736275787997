import { makeStyles } from '@fluentui/react'
import { fonts } from 'styles/variables'
import { flex } from 'styles/utils'

export const useAccountDetailsModalStyles = makeStyles({
  modal: {
    minHeight: 'auto !important',
    '.ms-Dialog-inner': {
      paddingBottom: '34px',
    },
  },
  form: {
    ...flex('row', 'end'),
    gap: '16px',
  },
  choiceGroupContainer: {
    ...flex('row', 'center'),
    gap: '8px',

    '.ms-ChoiceField': {
      marginTop: 0,
    },
  },
  section: {},
  reasonDropdown: {
    width: '140px',
  },
  field: {
    height: '32px',
    display: 'flex',
    alignItems: 'center',
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  label: {
    ...fonts.caption1,
    marginBottom: '4px',
    fontWeight: 600,
  },
  submitButton: {},
  errorMessage: {
    position: 'absolute',
    bottom: '-15px',
    ...fonts.caption1,
    color: 'rgb(164, 38, 44)',
  },
})
