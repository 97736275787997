import { makeStyles } from '@fluentui/react'
import { colors, borderRadius, space } from 'styles/variables'

export const useMainPageFiltersStyles = makeStyles(() => ({
  form: {
    padding: space.md,
    borderRadius: borderRadius[2],
    backgroundColor: colors.white,

    marginBottom: space.md,

    // display: 'grid',
    // gap: space.md,
    // alignItems: 'end',
    // gridTemplateColumns: 'repeat(5, 1fr)',
    display: 'flex',
    flexWrap: 'wrap',
    gap: space.md,
    alignItems: 'end',
  },
  number: {
    width: '200px',
  },
  fullName: {
    width: '268px',
  },
  customerService: {
    width: '150px',
  },
  address: {
    width: '250px',
  },
  phoneNumber: {
    width: '268px',
  },
  personType: {
    width: '200px',
  },
  company: {
    width: '268px',
  },
  requestNumber: {
    width: '200px',
  },
  submitButton: {
    width: '150px',
  },
  resetButton: {
    width: '180px',
  },
}))
