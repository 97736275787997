import React from 'react'
import { Checkbox, ChoiceGroup, Dropdown, PrimaryButton, TextField } from '@fluentui/react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import clsx from 'clsx'
import { useAccountDetailsModalStyles } from './styles'
import { connectedPhoneTypeList, connectedPhoneTypeOptions } from 'config/connectedPhoneType'
import { ownershipTypeList, ownershipTypeOptions } from 'config/ownershipType'
import { blockReasonTypeList, blockReasonTypeOptions } from 'config/blockReasonType'
import { ConnectedPhoneType, OwnershipType } from 'types'
import { phoneSchema } from 'utils/validation'
import { isMobilePhone } from 'utils/phone'

const formSchema = z
  .object({
    phone: phoneSchema,
    type: z.enum(connectedPhoneTypeList, { errorMap: () => ({ message: 'Обоʼязкове поле' }) }),
    ownership: z.enum(ownershipTypeList, { errorMap: () => ({ message: 'Обоʼязкове поле' }) }),
    reason: z.enum(blockReasonTypeList).nullable().optional(),
    stopList: z.boolean().optional(),
    comment: z.string().optional(),
  })
  .superRefine(({ stopList, reason }, refinementContext) => {
    if (stopList && !reason) {
      return refinementContext.addIssue({
        code: 'custom',
        path: ['reason'],
        message: 'Вкажіть причину з приводу стоп-листа',
      })
    }
    return refinementContext
  })

const localDefaultValues = {
  phone: '',
  type: ConnectedPhoneType.MOBILE,
  comment: '',
  ownership: OwnershipType.OWNER,
  reason: undefined,
  stopList: false,
}

export type AccountDetailsFormState = z.infer<typeof formSchema>

export interface AccountDetailsFormProps {
  onSubmit: (formValues: AccountDetailsFormState) => void
  defaultValues?: AccountDetailsFormState
  phoneEdit?: boolean
}

export const AccountDetailsForm: React.FC<AccountDetailsFormProps> = ({
  onSubmit,
  defaultValues = localDefaultValues,
  phoneEdit = true,
}) => {
  const classes = useAccountDetailsModalStyles()
  const {
    handleSubmit,
    control,
    reset,
    setError,
    setValue,
    formState: { errors, touchedFields },
  } = useForm<AccountDetailsFormState>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  const stopList = useWatch({ control, name: 'stopList' })

  React.useEffect(() => {
    reset(defaultValues)
  }, [reset, defaultValues])

  const handleStopListChange = (e?: React.FormEvent<HTMLElement | HTMLInputElement>) => {
    if (e && 'checked' in e.target && !e.target.checked) {
      setValue('reason', null)
    }
  }

  const handleTypeChange = (value: ConnectedPhoneType) => {
    setValue('type', value, { shouldDirty: true, shouldTouch: true })
  }

  const handlePhoneChange = (_: unknown, newValue?: string | undefined) => {
    if (!touchedFields.type) {
      setValue('type', newValue && isMobilePhone(newValue) ? ConnectedPhoneType.MOBILE : ConnectedPhoneType.HOME)
    }
    setValue('phone', newValue || '', {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    })
  }

  return (
    <form
      noValidate
      className={classes.form}
      onSubmit={handleSubmit(async (data) => {
        console.log(data)
        try {
          await onSubmit(data)
        } catch (err: any) {
          console.log(err)
          Object.entries(err).forEach(([key, value]) => {
            setError(key as keyof AccountDetailsFormState, { type: 'custom', message: value as string })
          })
        }
      })}
    >
      <div className={classes.section}>
        <div className={classes.label}>Номер*</div>
        <div className={classes.field}>
          {phoneEdit ? (
            <Controller
              name="phone"
              control={control}
              render={(props) => (
                <TextField
                  {...props.field}
                  errorMessage={errors[props.field?.name]?.message as string}
                  placeholder="Введіть номер"
                  type="number"
                  onChange={handlePhoneChange}
                />
              )}
            />
          ) : (
            <div>{defaultValues?.phone}</div>
          )}
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.label}>Тип*</div>
        <div className={classes.field}>
          <Controller
            name="type"
            control={control}
            render={(props) => (
              <>
                <ChoiceGroup
                  styles={{ flexContainer: classes.choiceGroupContainer }}
                  name={props.field.name}
                  selectedKey={props.field.value}
                  options={connectedPhoneTypeOptions}
                  onChange={(_, option) => handleTypeChange(option!.key as ConnectedPhoneType)}
                />
                {errors[props.field.name]?.message && (
                  <div className={classes.errorMessage}>{errors[props.field.name]?.message}</div>
                )}
              </>
            )}
          />
        </div>
      </div>

      <div className={clsx(classes.section)}>
        <div className={classes.label}>Власник*</div>
        <div className={classes.field}>
          <Controller
            name="ownership"
            control={control}
            render={(props) => (
              <>
                <ChoiceGroup
                  styles={{ flexContainer: classes.choiceGroupContainer }}
                  name={props.field.name}
                  selectedKey={props.field.value}
                  options={ownershipTypeOptions}
                  onChange={(_, option) => props.field.onChange(option!.key)}
                />
                {errors[props.field.name]?.message && (
                  <div className={classes.errorMessage}>{errors[props.field.name]?.message}</div>
                )}
              </>
            )}
          />
        </div>
      </div>

      <div className={clsx(classes.section)}>
        <div className={classes.label}>Причина</div>
        <div className={classes.field}>
          <Controller
            name="reason"
            control={control}
            render={(props) => (
              <Dropdown
                {...props.field}
                className={classes.reasonDropdown}
                errorMessage={errors[props.field?.name]?.message as string}
                placeholder="Введіть причину"
                options={blockReasonTypeOptions}
                onChange={(e, option) => props.field.onChange(option?.key)}
                selectedKey={props.field.value ?? null}
                required={stopList}
              />
            )}
          />
        </div>
      </div>

      <div className={clsx(classes.section, classes.centered)}>
        <div className={classes.label}>Стоп лист</div>
        <div className={classes.field}>
          <Controller
            name="stopList"
            control={control}
            render={(props) => (
              <Checkbox
                name={props.field.name}
                checked={props.field.value}
                onChange={(e) => {
                  props.field.onChange(e)
                  handleStopListChange(e)
                }}
              />
            )}
          />
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.label}>Коментар</div>
        <div className={classes.field}>
          <Controller
            name="comment"
            control={control}
            render={(props) => (
              <TextField
                {...props.field}
                errorMessage={errors[props.field?.name]?.message as string}
                placeholder="Введіть коментар"
              />
            )}
          />
        </div>
      </div>
      <PrimaryButton className={classes.submitButton} text="Зберегти" type="submit" />
    </form>
  )
}
