import type { RequestFormState } from 'components/RequestForm'
import type { CreateRequestRequestDto } from 'store/requests'

export const mapAccountToSubmit = (
  formState: RequestFormState
): Omit<
  CreateRequestRequestDto,
  'contactId' | 'personalAccountId' | 'incomePhoneNumber' | 'contactCenterPhoneNumber' | 'company' | 'number'
> => {
  return {
    supplyType: formState.supplyType,
    channelType: formState.channelType,
    reasonType: formState.reasonType,
    themeId: formState.themeId!,
    subThemeId: formState.subThemeId!,
    categoryId: formState.categoryId!,
    comment: formState.comment ?? '',
    keywords: formState.keywords?.map((keyword) => keyword.text) ?? [],
    escalation: formState.escalation,
  }
}
