import React from 'react'
import { Dialog } from '@fluentui/react'

import { MeterType } from 'types'
import { GeneralMeterReadingForm, GeneralMeterReadingFormState } from './GeneralMeterReadingForm'
import { ThreeZoneMeterReadingForm, ThreeZoneMeterReadingFormState } from './ThreeZoneMeterReadingForm'
import { TwoZoneMeterReadingForm, TwoZoneMeterReadingFormState } from './TwoZoneMeterReadingForm'

type NewReadingModalProps = {
  visible: boolean
  onClose: () => void
} & (
  | {
      type: MeterType.ONE_ZONE
      onSubmit: (formValues: GeneralMeterReadingFormState & { type: MeterType.ONE_ZONE }) => void
    }
  | {
      type: MeterType.TWO_ZONE
      onSubmit: (formValues: TwoZoneMeterReadingFormState & { type: MeterType.TWO_ZONE }) => void
    }
  | {
      type: MeterType.THREE_ZONE
      onSubmit: (formValues: ThreeZoneMeterReadingFormState & { type: MeterType.THREE_ZONE }) => void
    }
)

export type NewReadingFormState = Parameters<NewReadingModalProps['onSubmit']>['0']

export const NewReadingModal = ({ onClose, onSubmit, visible, type }: NewReadingModalProps) => {
  const Form = React.useMemo(() => {
    switch (type) {
      case MeterType.ONE_ZONE:
        return (
          <GeneralMeterReadingForm
            onCancel={onClose}
            onSubmit={(fs) => onSubmit({ ...fs, type: MeterType.ONE_ZONE })}
          />
        )
      case MeterType.TWO_ZONE:
        return (
          <TwoZoneMeterReadingForm
            onCancel={onClose}
            onSubmit={(fs) => onSubmit({ ...fs, type: MeterType.TWO_ZONE })}
          />
        )
      case MeterType.THREE_ZONE:
        return (
          <ThreeZoneMeterReadingForm
            onCancel={onClose}
            onSubmit={(fs) => onSubmit({ ...fs, type: MeterType.THREE_ZONE })}
          />
        )
      default:
        return null
    }
  }, [type, onSubmit, onClose])

  return (
    <Dialog
      maxWidth={624}
      hidden={!visible}
      onDismiss={onClose}
      dialogContentProps={{
        showCloseButton: true,
        title: 'Передача показань лічильника',
        subText: 'Заповніть показання від клієнта та збережіть форму.',
      }}
    >
      {Form}
    </Dialog>
  )
}
