import React from 'react'
import { Shimmer } from '@fluentui/react'

import { Box } from 'components/Box'

interface SkeletonProps {
  rows?: number
  box?: boolean
  boxClassName?: string
}

export const Skeleton: React.FC<SkeletonProps> = ({ rows = 4, box = true, boxClassName }) => {
  const child = React.useMemo(() => {
    return (
      <>
        {Array(rows)
          .fill(0)
          .map((_, index) => (
            <Shimmer key={index} style={{ marginBottom: '8px' }} />
          ))}
        <Shimmer width="75px" />
      </>
    )
  }, [rows])

  if (box) {
    return <Box className={boxClassName}>{child}</Box>
  }

  return child
}
