import { makeStyles } from '@fluentui/react'
import { space, fonts, colors } from 'styles/variables'

export const usePersonalAccountListStyles = makeStyles(() => ({
  header: {
    ...fonts.caption1,
    color: colors.grey44,
    padding: `${space.xs} ${space.md}`,
    display: 'grid',
    gap: space.md,
    alignItems: 'center',
    gridTemplateColumns: '1fr 5fr 25fr 3fr 5fr 5fr',
  },
}))
