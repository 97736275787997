import type { ReportPageFiltersFormState } from 'components/ReportPageFilters'
import type {
  GetReportsByB2bRequestDto,
  GetReportsByKeywordsRequestDto,
  GetReportsByThemeRequestDto,
} from 'store/reports'

export const mapReportByThemeToSubmit = (formState: ReportPageFiltersFormState): GetReportsByThemeRequestDto => {
  return {
    company: formState.company!,
    from: formState.period?.[0]?.toISOString(),
    to: formState.period?.[1]?.toISOString(),
    supplyType: formState.supplyType!,
    requestChannelType: formState.requestChannelType!,
  }
}

export const mapReportByKeywordsToSubmit = (formState: ReportPageFiltersFormState): GetReportsByKeywordsRequestDto => {
  return {
    ...mapReportByThemeToSubmit(formState),
    keywords: formState.keywords!.map((keyword) => keyword.text).join(','),
  }
}

export const mapReportByB2bToSubmit = (formState: ReportPageFiltersFormState): GetReportsByB2bRequestDto => {
  return {
    ...mapReportByThemeToSubmit(formState),
    personType: formState.personType!,
  }
}
