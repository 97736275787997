import React from 'react'

interface UseScrollProps {
  childRef: React.RefObject<Element>
  callback: () => Promise<void> 
  hasMore: boolean
  deps: unknown[]
}

export const useScroll = ({ childRef, callback, hasMore, deps = [] }: UseScrollProps) => {
  const stableCallback = React.useCallback(callback, deps)
  const stableHasMore = React.useRef(hasMore)
  const isLoading = React.useRef(false) // Track if callback is in progress
  stableHasMore.current = hasMore

  React.useEffect(() => {
    const checkIfInViewPort = async () => {
      const rect = childRef.current?.getBoundingClientRect()
      const isInViewPort =
        rect &&
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)

      if (isInViewPort && stableHasMore.current && !isLoading.current) {
        isLoading.current = true
        await stableCallback()
        isLoading.current = false
      }
    }

    window.addEventListener('scroll', checkIfInViewPort)

    // Check initially if already in viewport
    checkIfInViewPort()

    return () => window.removeEventListener('scroll', checkIfInViewPort)
  }, [childRef, stableCallback])
}
