import { makeStyles } from '@fluentui/react'
import { colors, space } from 'styles/variables'
import { flex } from 'styles/utils'

export const useIncomingCallDetailsStyles = makeStyles({
  container: {
    ...flex('row', 'center'),
    marginBottom: space.md,
  },
  section: {
    padding: space.sm,
    display: 'flex',

    '& + &': {
      marginLeft: space.md,
    },
  },
  element: {
    display: 'flex',

    '& + &': {
      marginLeft: space.xl,
    },
  },
  elementInfo: {
    ...flex('column'),
    marginLeft: space.xs,
    strong: {
      color: colors.grey14,
    },
    span: {
      color: colors.grey44,
    },
  },
})
