import { makeStyles } from '@fluentui/react'
import { space, borderRadius, colors } from 'styles/variables'

export const useContactPageStyles = makeStyles({
  container: {
    display: 'grid',
    gap: '16px',
    gridTemplateColumns: '880px 1fr',
    gridTemplateAreas: `
      'form details'
      'contact contact'
      'history history'
    `,
  },
  form: {
    marginBottom: space.md,
    gridArea: 'form',
  },
  details: {
    gridArea: 'details',
  },
  contact: {
    gridArea: 'contact',
  },
  history: {
    borderRadius: borderRadius[2],
    backgroundColor: `${colors.white} !important`,
    gridArea: 'history',
  },
})
