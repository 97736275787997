import { PersonalAccountConnectionStatus } from 'types'

const personalAccountConnectionStatusLabel: Record<PersonalAccountConnectionStatus, string> = {
  [PersonalAccountConnectionStatus.CONNECTED]: 'Підключений',
  [PersonalAccountConnectionStatus.DISCONNECTED]: 'Відключений',
}

export const getPersonalAccountConnectionStatusLabel = (
  personalAccountConnectionStatus?: PersonalAccountConnectionStatus
): string => {
  if (!personalAccountConnectionStatus) return ''
  return personalAccountConnectionStatusLabel[personalAccountConnectionStatus]
}
