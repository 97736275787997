import React from 'react'
import { Dialog } from '@fluentui/react'

import { ContactForm, ContactFormProps } from 'components/ContactForm'

interface NewContactModalProps extends ContactFormProps {
  visible: boolean
  onClose: () => void
}

export const NewContactModal: React.FC<NewContactModalProps> = ({ visible, onClose, ...formProps }) => {
  return (
    <Dialog
      minWidth={624}
      hidden={!visible}
      onDismiss={onClose}
      closeButtonAriaLabel="true"
      dialogContentProps={{
        showCloseButton: true,
        title: 'Додавання нового контакту',
        subText: 'Вкажіть та збережіть дані клієнта, щоб оформити звернення.',
      }}
    >
      <ContactForm {...formProps} />
    </Dialog>
  )
}
