import { makeStyles } from '@fluentui/react'
import { colors } from 'styles/variables'
import { flex } from 'styles/utils'

export const usePersonalAccountConnectedPhonesStyles = makeStyles({
  title: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28px',
    color: colors.grey14,
    margin: 0,
  },
  header: {
    ...flex('row', 'center', 'space-between'),
    marginBottom: '10px',
  },
  tdCenter: {
    textAlign: 'center',
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    maxWidth: '100%',

    tbody: {
      tr: {
        height: '60px',
        borderBottom: '1px solid #E6E6E6',
      },
    },
  },
})
