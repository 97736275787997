import React from 'react'

import { Box } from 'components/Box'
import { PersonalAccount } from 'store/personal-accounts'
import { getSupplyTypeLabel } from 'config/supplyType'
import { getCompanyTypeLabel } from 'config/companyType'
import { getContactTypeLabel } from 'config/contractType'
import { PersonType, PersonalAccountStatus, SupplyType } from 'types'
import { getPersonalAccountConnectionStatusLabel } from 'config/personalAccountConnectionStatus'

import { usePersonalAccountDetailsStyles } from './styles'

interface PersonalAccountDetailsProps {
  className?: string
  personalAccount: Pick<
    PersonalAccount,
    | 'id'
    | 'number'
    | 'ownerName'
    | 'supplyAddress'
    | 'oldNumber'
    | 'company'
    | 'distributionSystemOperator'
    | 'supplyType'
    | 'energyOffice'
    | 'webAppRegistration'
    | 'connectionStatus'
    | 'status'
    | 'startDate'
    | 'endDate'
    | 'personType'
    | 'taxNumber'
    | 'directorName'
    | 'representativeName'
    | 'contractType'
  >
}

export const PersonalAccountDetails: React.FC<PersonalAccountDetailsProps> = ({ className, personalAccount }) => {
  const classes = usePersonalAccountDetailsStyles()

  return (
    <Box className={className}>
      <p className={classes.title}>Деталі особового рахунку</p>
      <dl>
        {[
          {
            show: true,
            key: 'ownerName',
            term: 'Власник',
            details: personalAccount.ownerName,
            className: classes.bold,
          },
          {
            show: true,
            key: 'status',
            term: 'Статус',
            details:
              personalAccount.status === PersonalAccountStatus.ACTIVE
                ? `Активний з ${personalAccount.startDate}`
                : `Зупинений з ${personalAccount.endDate}`,
          },
          {
            show: personalAccount.personType === PersonType.LEGAL_ENTITY,
            key: 'taxNumber',
            term: 'ЄДРПОУ',
            details: personalAccount.taxNumber,
          },
          {
            show: personalAccount.personType === PersonType.LEGAL_ENTITY,
            key: 'directorName',
            term: 'ПІБ директора',
            details: personalAccount.directorName,
          },
          {
            show: personalAccount.personType === PersonType.LEGAL_ENTITY,
            key: 'representativeName',
            term: 'ПІБ представника',
            details: personalAccount.representativeName,
          },
          {
            show: true,
            key: 'supplyAddress',
            term: 'Адреса постачання',
            details: personalAccount.supplyAddress,
          },
          {
            show:
              personalAccount.personType === PersonType.LEGAL_ENTITY &&
              personalAccount.supplyType === SupplyType.ELECTRICITY,
            key: 'contractType',
            term: 'Тип договору',
            details: getContactTypeLabel(personalAccount.contractType),
          },
          {
            show: personalAccount.supplyType === SupplyType.ELECTRICITY,
            key: 'oldNumber',
            term: 'Старий номер О/Р',
            details: personalAccount.oldNumber,
          },
          {
            show: true,
            key: 'company',
            term: 'Постачальник',
            details: getCompanyTypeLabel(personalAccount.company),
          },
          {
            show: personalAccount.supplyType === SupplyType.ELECTRICITY,
            key: 'osr',
            term: 'ОСР',
            details: personalAccount.distributionSystemOperator,
          },
          {
            show: personalAccount.supplyType === SupplyType.NATURAL_GAS,
            key: 'gtc',
            term: 'ГТС',
            details: personalAccount.distributionSystemOperator,
          },
          {
            show: true,
            key: 'supplyType',
            term: 'Постачання',
            details: getSupplyTypeLabel(personalAccount.supplyType),
          },
          {
            show: true,
            key: 'energyOffice',
            term: 'Енергоофіс',
            details: personalAccount.energyOffice,
          },
          {
            show: true,
            key: 'webAppRegistration',
            term: 'Реєстрація в О/К',
            details: personalAccount.webAppRegistration ? 'Так' : 'Ні',
          },
          {
            show: true,
            key: 'connectionStatus',
            term: 'Статус',
            details: getPersonalAccountConnectionStatusLabel(personalAccount.connectionStatus),
          },
        ]
          .filter((el) => el.show)
          .map((el) => {
            return (
              <div key={el.key} className={classes.group}>
                <dt>{el.term}</dt>
                <dd className={el.className}>{el.details}</dd>
              </div>
            )
          })}
      </dl>
    </Box>
  )
}
