import { MeterType } from 'types'

const meterTypeLabel: Record<MeterType, string> = {
  [MeterType.ONE_ZONE]: '1-з',
  [MeterType.TWO_ZONE]: '2-з',
  [MeterType.THREE_ZONE]: '3-з',
}

export const getMeterTypeLabel = (meterType?: MeterType): string => {
  if (!meterType) return ''
  return meterTypeLabel[meterType]
}
