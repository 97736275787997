export const filterQueryParams = <T extends Record<string, any>>(params: T): T => {
  const filteredParams: T = { ...params }

  Object.keys(filteredParams).forEach((key) => {
    if (filteredParams[key] === undefined || filteredParams[key] === null || filteredParams[key] === '') {
      delete filteredParams[key]
    }
  })

  return filteredParams
}
