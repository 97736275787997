import { makeStyles } from '@fluentui/react'
import { flex } from 'styles/utils'
import { colors, fonts, space } from 'styles/variables'

export const useIncomingCallPageStyles = makeStyles({
  header: {
    ...flex('row', 'center', 'space-between'),
  },
  title: {
    ...fonts.title3,
    color: colors.grey14,
    marginTop: '0px',
    marginBottom: space.md,
  },
})
