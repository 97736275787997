import { RequestReasonType, Option } from 'types'

const requestReasonTypeLabel: Record<RequestReasonType, string> = {
  [RequestReasonType.CONSULTATION]: 'Консультація',
  [RequestReasonType.INTERMEDIATE_RESPONSE]: 'Проміжна відповідь',
  [RequestReasonType.COMPLIANT]: 'Скарга',
  [RequestReasonType.APPRECIATION]: 'Подяка',
  [RequestReasonType.STATEMENT]: 'Заява',
  [RequestReasonType.PROPOSAL]: 'Пропозиція',
}

export const getRequestReasonTypeLabel = (requestReasonType?: RequestReasonType): string => {
  if (!requestReasonType) return ''
  return requestReasonTypeLabel[requestReasonType]
}

export const requestReasonTypeList = [
  RequestReasonType.CONSULTATION,
  RequestReasonType.INTERMEDIATE_RESPONSE,
  RequestReasonType.COMPLIANT,
  RequestReasonType.APPRECIATION,
  RequestReasonType.STATEMENT,
  RequestReasonType.PROPOSAL,
] as const

export const requestReasonTypeOptions: Option<RequestReasonType>[] = requestReasonTypeList.map((key) => ({
  key,
  text: getRequestReasonTypeLabel(key),
}))
