import { z } from 'zod'
import { validatePhoneForE164 } from './validators/validatePhoneForE164'

export const keywordSchema = z.object({ key: z.string(), text: z.string() })

const phoneValidationMessage = 'Номер телефону має бути в форматі 380XXXXXXXXX'

export const phoneSchema = z.string().refine(validatePhoneForE164, { message: phoneValidationMessage })

export const phoneSchemaOptional = z
  .string()
  .refine(
    (phone) => {
      const extractedPhone = phone?.match(/\d+/g)?.join('')
      if (!extractedPhone) return true
      return validatePhoneForE164(extractedPhone)
    },
    { message: phoneValidationMessage }
  )
  .optional()
