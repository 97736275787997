import React from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { PrimaryButton, TextField, Dropdown } from '@fluentui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import { RequestType, Option, EscalationStatusType } from 'types'
import { requestTypeList, requestTypeOptions } from 'config/requestType'
import { requestReasonTypeList, requestReasonTypeOptions } from 'config/requestReasonType'
import { keywordSchema } from 'utils/validation'
import { Box } from 'components/Box'
import { TagInput } from 'components/TagInput'
import { ReactComponent as FloppyDiscIcon } from 'assets/icons/floppy-disk-icon.svg'
import {
  useGetRequestsThemesDictionaryQuery,
  useGetRequestsCategoryDictionaryQuery,
  useGetRequestsSubThemesDictionaryQuery,
} from 'store/dictionaries'
import { supplyTypeList, supplyTypeOptions } from 'config/supplyType'
import { escalationStatusTypeList, escalationStatusTypeOptions } from 'config/escalationStatusType'

import { useRequestFormStyles } from './styles'

const formSchema = z.object({
  supplyType: z.enum(supplyTypeList, { required_error: 'Оберіть тип постачання' }),
  channelType: z.enum(requestTypeList, { required_error: 'Оберіть тип' }),
  reasonType: z.enum(requestReasonTypeList, { required_error: 'Оберіть причину' }),
  themeId: z.number({ errorMap: () => ({ message: 'Оберіть тему' }) }),
  subThemeId: z.number({ errorMap: () => ({ message: 'Оберіть підтему' }) }),
  categoryId: z.number({ errorMap: () => ({ message: 'Оберіть категорію' }) }),
  comment: z.string().max(3000).optional(),
  keywords: z.array(keywordSchema).max(10, { message: 'Максимум 10 слів' }).optional(),
  escalation: z.enum(escalationStatusTypeList, { required_error: 'Оберіть статус ескалації' }).optional(),
})

export type RequestFormState = z.infer<typeof formSchema>

interface RequestFormProps {
  className?: string
  onSubmit: (formValues: RequestFormState) => void
  defaultValues?: Partial<RequestFormState>
  disabled?: boolean
  controlProps?: {
    supplyType?: Partial<React.ComponentProps<typeof Dropdown>>
    channelType?: Partial<React.ComponentProps<typeof Dropdown>>
    reasonType?: Partial<React.ComponentProps<typeof Dropdown>>
    themeId?: Partial<React.ComponentProps<typeof Dropdown>>
    subThemeId?: Partial<React.ComponentProps<typeof Dropdown>>
    categoryId?: Partial<React.ComponentProps<typeof Dropdown>>
    comment?: Partial<React.ComponentProps<typeof TextField>>
    keywords?: Partial<React.ComponentProps<typeof TagInput>>
    escalation?: Partial<React.ComponentProps<typeof Dropdown>>
    button?: Partial<React.ComponentProps<typeof PrimaryButton>>
  }
}

const localDefaultValues = {
  supplyType: undefined,
  channelType: RequestType.INCOMING_CALL,
  reasonType: undefined,
  themeId: undefined,
  subThemeId: undefined,
  categoryId: undefined,
  comment: undefined,
  keywords: undefined,
  escalation: EscalationStatusType.NOT_REQUIRED,
}

const EMPTY_VALUE = null as any

export const RequestForm: React.FC<RequestFormProps> = ({
  onSubmit,
  className,
  defaultValues,
  controlProps,
  disabled,
}) => {
  const classes = useRequestFormStyles()

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<RequestFormState>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ...localDefaultValues,
      ...defaultValues,
    },
  })

  // --- watch ---
  const supplyType = useWatch({ control, name: 'supplyType' })
  const themeId = useWatch({ control, name: 'themeId' })!
  const subThemeId = useWatch({ control, name: 'subThemeId' })!

  // --- queries ---
  const { data: requestsThemesDictionaryData = [] } = useGetRequestsThemesDictionaryQuery()
  const { data: requestsSubThemesDictionary = [] } = useGetRequestsSubThemesDictionaryQuery(
    { themeId },
    { skip: !themeId }
  )
  const { data: requestsCategoryDictionary = [] } = useGetRequestsCategoryDictionaryQuery(
    { themeId, subThemeId },
    { skip: !themeId || !subThemeId }
  )

  // --- options ---
  const requestsThemesOptions: Option<number>[] = React.useMemo(() => {
    if (!supplyType) return []

    return requestsThemesDictionaryData
      .filter((theme) => theme.supplyType === supplyType)
      .map((theme) => ({ key: theme.id, text: theme.name }))
  }, [requestsThemesDictionaryData, supplyType])

  const requestsSubThemesOptions: Option<number>[] = React.useMemo(() => {
    if (!themeId) return []

    return requestsSubThemesDictionary.map((subTheme) => ({ key: subTheme.id, text: subTheme.name }))
  }, [requestsSubThemesDictionary, themeId])

  const requestsCategoryOptions: Option<number>[] = React.useMemo(() => {
    if (!subThemeId) return []

    return requestsCategoryDictionary.map((category) => ({ key: category.id, text: category.name }))
  }, [requestsCategoryDictionary, subThemeId])

  // --- handlers ---
  const clearTheme = () => {
    setValue('themeId', EMPTY_VALUE)
    setValue('subThemeId', EMPTY_VALUE)
    setValue('categoryId', EMPTY_VALUE)
  }

  const clearSubTheme = () => {
    setValue('subThemeId', EMPTY_VALUE)
    setValue('categoryId', EMPTY_VALUE)
  }

  const clearCategory = () => {
    setValue('categoryId', EMPTY_VALUE)
  }

  // --- use effects ---
  React.useEffect(() => {
    reset(defaultValues ?? localDefaultValues)
  }, [reset, defaultValues])

  return (
    <Box className={className}>
      <h1 className={classes.title}>Оформлення звернення</h1>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Controller
          name="supplyType"
          control={control}
          render={(props) => (
            <Dropdown
              required
              onChange={(e, option) => {
                props.field.onChange(option?.key)
                clearTheme()
              }}
              selectedKey={props.field.value || null}
              errorMessage={errors[props.field?.name]?.message as string}
              label="Вид постачання"
              placeholder="Вид постачання"
              options={supplyTypeOptions}
              className={classes.supplyType}
              {...controlProps?.supplyType}
              disabled={controlProps?.supplyType?.disabled || disabled}
            />
          )}
        />
        <Controller
          name="channelType"
          control={control}
          render={(props) => (
            <Dropdown
              required
              disabled
              selectedKey={props.field.value || null}
              onChange={(e, option) => props.field.onChange(option?.key)}
              errorMessage={errors[props.field?.name]?.message as string}
              label="Тип"
              placeholder="Введіть тип"
              options={requestTypeOptions}
              className={classes.requestType}
              {...controlProps?.channelType}
            />
          )}
        />
        <Controller
          name="reasonType"
          control={control}
          render={(props) => (
            <Dropdown
              required
              selectedKey={props.field.value || null}
              onChange={(e, option) => props.field.onChange(option?.key)}
              errorMessage={errors[props.field?.name]?.message as string}
              label="Причина"
              placeholder="Оберіть причину"
              options={requestReasonTypeOptions}
              className={classes.requestReasonType}
              {...controlProps?.reasonType}
              disabled={controlProps?.reasonType?.disabled || disabled}
            />
          )}
        />
        <Controller
          name="themeId"
          control={control}
          render={(props) => (
            <Dropdown
              required
              selectedKey={props.field.value || null}
              onChange={(e, option) => {
                props.field.onChange(option?.key)
                clearSubTheme()
              }}
              errorMessage={errors[props.field?.name]?.message as string}
              label="Тема"
              placeholder="Оберіть тему"
              options={requestsThemesOptions}
              className={classes.theme}
              {...controlProps?.themeId}
              disabled={!requestsThemesOptions?.length || controlProps?.themeId?.disabled || disabled}
            />
          )}
        />
        <Controller
          name="subThemeId"
          control={control}
          render={(props) => (
            <Dropdown
              required
              selectedKey={props.field.value || null}
              onChange={(e, option) => {
                props.field.onChange(option?.key)
                clearCategory()
              }}
              errorMessage={errors[props.field?.name]?.message as string}
              label="Підтема"
              placeholder="Оберіть підтему"
              options={requestsSubThemesOptions}
              className={classes.subTheme}
              {...controlProps?.subThemeId}
              disabled={!requestsSubThemesOptions?.length || controlProps?.subThemeId?.disabled || disabled}
            />
          )}
        />
        <Controller
          name="categoryId"
          control={control}
          render={(props) => (
            <Dropdown
              required
              selectedKey={props.field.value || null}
              onChange={(e, option) => props.field.onChange(option?.key)}
              errorMessage={errors[props.field?.name]?.message as string}
              label="Категорія"
              placeholder="Оберіть підтему"
              options={requestsCategoryOptions}
              className={classes.category}
              {...controlProps?.categoryId}
              disabled={!requestsCategoryOptions?.length || controlProps?.categoryId?.disabled || disabled}
            />
          )}
        />
        <Controller
          name="comment"
          control={control}
          render={(props) => (
            <TextField
              {...props.field}
              errorMessage={errors[props.field?.name]?.message as string}
              label="Коментар"
              placeholder="Введіть коментар"
              className={classes.comment}
              multiline
              rows={3}
              resizable={false}
              {...controlProps?.comment}
              disabled={controlProps?.comment?.disabled || disabled}
            />
          )}
        />
        <Controller
          name="keywords"
          control={control}
          render={(props) => {
            const disabledField = controlProps?.keywords?.disabled || disabled
            const hideLabel = disabledField && !props.field.value?.length

            return (
              <TagInput
                label="Ключові слова"
                onChange={props.field.onChange}
                value={props.field.value}
                placeholder="Введіть і додайте слова по черзі"
                className={classes.keywords}
                errorMessage={errors[props.field?.name]?.message as string}
                {...controlProps?.keywords}
                disabled={disabledField}
                hideLabel={hideLabel}
              />
            )
          }}
        />
        <Controller
          name="escalation"
          control={control}
          render={(props) => (
            <Dropdown
              selectedKey={props.field.value || null}
              onChange={(e, option) => props.field.onChange(option?.key)}
              errorMessage={errors[props.field?.name]?.message as string}
              label="Ескалація"
              placeholder="Виберіть"
              options={escalationStatusTypeOptions}
              className={classes.escalation}
              {...controlProps?.escalation}
              disabled={controlProps?.escalation?.disabled || disabled}
            />
          )}
        />
        <PrimaryButton disabled={disabled} type="submit" className={classes.submit} {...controlProps?.button}>
          <FloppyDiscIcon />
          &nbsp;Зберегти звернення і закрити
        </PrimaryButton>
      </form>
    </Box>
  )
}
