import { PayloadAction, createAsyncThunk, createSlice, isRejectedWithValue } from '@reduxjs/toolkit'
import axios from 'libs/axios'
import { type User } from 'types'

interface AccountState {
  user: User | null
}

export const getMe = createAsyncThunk('account/getMe', async () => {
  try {
    const { data } = await axios({ method: 'GET', url: 'auth/me' })

    return data
  } catch (err) {
    const error = err as any

    return isRejectedWithValue({
      ...error?.payload,
      status: error?.response?.status,
    })
  }
})

const initialState: AccountState = {
  user: null,
}

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
    },
  },
  extraReducers: {
    [getMe.fulfilled.type]: (state, action: PayloadAction<User>) => {
      state.user = action.payload
    },
  },
})

export const { setUser } = accountSlice.actions
