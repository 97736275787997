import React from 'react'
import { DialogFooter, Dropdown, PrimaryButton, TextField, DefaultButton } from '@fluentui/react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import clsx from 'clsx'

import { PersonType } from 'types'
import { personTypeOptions, personTypeList } from 'config/personType'
import { companyTypeList, companyTypeOptions } from 'config/companyType'
import { phoneSchemaOptional } from 'utils/validation'

import { useContactFormStyles } from './styles'

const requiredError = 'Поле обовʼякове'

const formSchema = z
  .object({
    company: z.enum(companyTypeList, { errorMap: () => ({ message: requiredError }) }),
    personType: z.enum(personTypeList, { errorMap: () => ({ message: requiredError }) }),
    firstName: z.string({ required_error: requiredError }),
    lastName: z.string().optional(),
    middleName: z.string().optional(),
    companyName: z.string().optional(),
    phoneNumber: phoneSchemaOptional,
    comment: z.string().optional(),
  })
  .superRefine(({ personType, company }, refinementContext) => {
    if (personType === PersonType.LEGAL_ENTITY && !company) {
      return refinementContext.addIssue({
        code: 'custom',
        path: ['company'],
        message: requiredError,
      })
    }
    return refinementContext
  })

export type ContactFormState = z.infer<typeof formSchema>

export interface ContactFormProps {
  className?: string
  defaultValues?: Partial<ContactFormState>
  onSubmit: (formValues: ContactFormState) => void
  onCancel: () => void
  submitButtonText?: string
  cancelButtonText?: string
  controlProps?: {
    company?: Partial<React.ComponentProps<typeof Dropdown>>
  }
}

export const ContactForm: React.FC<ContactFormProps> = ({
  onSubmit,
  defaultValues,
  className,
  onCancel,
  submitButtonText = 'Зберегти і перейти',
  cancelButtonText = 'Скасувати',
  controlProps,
}) => {
  const classes = useContactFormStyles()
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<ContactFormState>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      personType: PersonType.NATURAL_PERSON,
      ...defaultValues,
    },
  })
  const personType = useWatch({ control, name: 'personType' })

  return (
    <form
      noValidate
      onSubmit={handleSubmit(async (data) => {
        console.log(data)
        try {
          await onSubmit(data)
        } catch (err: any) {
          console.log(err)
          Object.entries(err).forEach(([key, value]) => {
            setError(key as keyof ContactFormState, { type: 'custom', message: value as string })
          })
        }
      })}
      className={clsx(classes.form, className, {
        [classes.naturalPersonTemplate]: personType === PersonType.NATURAL_PERSON,
        [classes.legalEntityTemplate]: personType === PersonType.LEGAL_ENTITY,
      })}
    >
      <Controller
        name="company"
        control={control}
        render={(props) => (
          <Dropdown
            {...props.field}
            required
            errorMessage={errors[props.field?.name]?.message as string}
            onChange={(e, option) => props.field.onChange(option?.key)}
            className={classes.company}
            selectedKey={props.field.value}
            label="Компанія постачальник"
            placeholder="Оберіть компанію постачальник"
            options={companyTypeOptions}
            {...controlProps?.company}
          />
        )}
      />
      <Controller
        name="personType"
        control={control}
        render={(props) => (
          <Dropdown
            {...props.field}
            required
            selectedKey={props.field.value}
            onChange={(e, option) => props.field.onChange(option?.key)}
            errorMessage={errors[props.field?.name]?.message as string}
            className={classes.personType}
            label="Ознака клієнта"
            placeholder="Введіть ознаку клієнта"
            options={personTypeOptions}
          />
        )}
      />
      <Controller
        name="firstName"
        control={control}
        render={(props) => (
          <TextField
            {...props.field}
            required
            autoComplete="name"
            errorMessage={errors[props.field?.name]?.message as string}
            className={classes.firstName}
            {...(personType === PersonType.NATURAL_PERSON && {
              label: 'Імʼя клієнта',
              placeholder: 'Введіть імʼя клієнта',
            })}
            {...(personType === PersonType.LEGAL_ENTITY && {
              label: 'Iмʼя представника',
              placeholder: 'Введіть імʼя представника',
            })}
          />
        )}
      />
      <Controller
        name="lastName"
        control={control}
        render={(props) => (
          <TextField
            {...props.field}
            autoComplete="off"
            errorMessage={errors[props.field?.name]?.message as string}
            className={classes.lastName}
            {...(personType === PersonType.NATURAL_PERSON && {
              label: 'Прізвище клієнта',
              placeholder: 'Введіть прізвище клієнта',
            })}
            {...(personType === PersonType.LEGAL_ENTITY && {
              label: 'Прізвище представника',
              placeholder: 'Введіть прізвище представника',
            })}
          />
        )}
      />
      <Controller
        name="middleName"
        control={control}
        render={(props) => (
          <TextField
            {...props.field}
            autoComplete="off"
            errorMessage={errors[props.field?.name]?.message as string}
            className={classes.middleName}
            {...(personType === PersonType.NATURAL_PERSON && {
              label: 'По батькові клієнта',
              placeholder: 'Введіть по батькові клієнта',
            })}
            {...(personType === PersonType.LEGAL_ENTITY && {
              label: 'По батькові представника',
              placeholder: 'Введіть по батькові представника',
            })}
          />
        )}
      />
      <Controller
        name="phoneNumber"
        control={control}
        render={(props) => (
          <TextField
            {...props.field}
            autoComplete="off"
            errorMessage={errors[props.field?.name]?.message as string}
            className={classes.phoneNumber}
            label="Номер телефону"
            placeholder="Введіть номер"
            type="number"
          />
        )}
      />
      {personType === PersonType.LEGAL_ENTITY && (
        <Controller
          name="companyName"
          control={control}
          render={(props) => (
            <TextField
              {...props.field}
              errorMessage={errors[props.field?.name]?.message as string}
              className={classes.companyName}
              label="Назва компанії клієнта"
              placeholder="Введіть назва компанії клієнта"
            />
          )}
        />
      )}
      <Controller
        name="comment"
        control={control}
        render={(props) => (
          <TextField
            {...props.field}
            errorMessage={errors[props.field?.name]?.message as string}
            className={classes.comment}
            multiline
            rows={3}
            resizable={false}
            label="Коментар"
            placeholder="Введіть коментар"
          />
        )}
      />
      <DialogFooter className={classes.controls} styles={{ actionsRight: { justifyContent: 'flex-start' } }}>
        <PrimaryButton className={classes.submitButton} text={submitButtonText} type="submit" />
        <DefaultButton className={classes.resetButton} text={cancelButtonText} type="reset" onClick={onCancel} />
      </DialogFooter>
    </form>
  )
}
