import clsx from 'clsx'

import { Table } from 'components/Table'

import { useReportByThemeTableStyles } from './styles'

interface ReportByThemeTableItem {
  id?: string
  theme: string
  subTheme: string
  category: string
  requestsCount: number
}

export interface ReportByThemeTableProps {
  data: ReportByThemeTableItem[]
}

export const ReportByThemeTable: React.FC<ReportByThemeTableProps> = ({ data }) => {
  const classes = useReportByThemeTableStyles()

  return (
    <Table<ReportByThemeTableItem>
      className={clsx(classes.table, classes.themeTable)}
      data={data}
      showHeader
      columns={[
        { id: 'theme', width: '25%', label: 'Тема' },
        { id: 'subTheme', width: '25%', label: 'Підтема' },
        { id: 'category', width: '25%', label: 'Категорія' },
        { id: 'requestsCount', width: '25%', label: 'Кількість вхідних дзвінків' },
      ]}
    />
  )
}
