/* eslint-disable class-methods-use-this */
import {UserAgentApplication, Configuration} from 'msal'

import {CLIENT_ID, TENANT_ID, TOKEN_SCOPE} from './config'

const locationOrigin = window.location ? window.location.origin : undefined

const msalConfig: Configuration = {
  auth: {
    clientId: CLIENT_ID,
    authority: `https://login.microsoftonline.com/${TENANT_ID}`,
    redirectUri: locationOrigin,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
}

const ACCESS_TOKEN_NAME = 'accessToken'

class MsalAuth {
  private msal: UserAgentApplication

  constructor() {
    this.msal = new UserAgentApplication(msalConfig)
    this.logout = this.logout.bind(this)
  }

  getMsalAccountData() {
    return this.msal.getAccount()
  }

  getAccessToken() {
    return this.msal.acquireTokenSilent({
      scopes: [TOKEN_SCOPE],
    })
  }

  redirectToLogin() {
    this.msal.loginRedirect({scopes: ['user.read']})
  }

  acquireTokenRedirect() {
    return this.msal.acquireTokenRedirect({
      scopes: [TOKEN_SCOPE],
    })
  }

  getGraphAccessToken() {
    return this.msal.acquireTokenSilent({scopes: ['user.read']})
  }

  requiresInteraction = (errorMessage: any) => {
    if (!errorMessage?.length) {
      return false
    }

    return (
      errorMessage.indexOf('consent_required') > -1 ||
      errorMessage.indexOf('interaction_required') > -1 ||
      errorMessage.indexOf('login_required') > -1
    )
  }

  isUserLoginError = (errorMessage: any) => {
    if (!errorMessage?.length) {
      return false
    }

    return errorMessage.indexOf('user_login_error') > -1 || errorMessage.indexOf('null_or_empty_id_token') > -1
  }

  logout() {
    this.msal.logout()
  }
}

export {ACCESS_TOKEN_NAME}
export default MsalAuth
