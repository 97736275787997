import React from 'react'
import { Helmet } from 'components/Helmet'
import { useLazyGetReportsByB2bQuery } from 'store/reports'
import { Skeleton } from 'components/Skeleton'
import { PageWrapper } from 'components/PageWrapper'
import { ReportPageFilters, type ReportPageFiltersFormState } from 'components/ReportPageFilters'
import { withSupervisorRoleRestriction } from 'hocs/withSupervisorRoleRestriction'
import reportIcon from 'assets/icons/report-icon.svg'
import { mapReportByB2bToSubmit } from 'utils/mappers/mapReportToSubmit'

import { ReportByB2B } from './components/ReportByB2B'
import { useDownloadReportsByB2bMutation } from 'store/reports/endpoints'

export const ReportsByB2bPage = withSupervisorRoleRestriction(() => {
  const [formState, setFormState] = React.useState<ReportPageFiltersFormState>()
  const [generateReport, { isLoading, data }] = useLazyGetReportsByB2bQuery()
  const [downloadReport] = useDownloadReportsByB2bMutation()

  const handleSubmit = async (formState: ReportPageFiltersFormState) => {
    await generateReport(mapReportByB2bToSubmit(formState))
    setFormState(formState)
  }

  const handleDownload = async () => {
    if (!formState) return
    await downloadReport(mapReportByB2bToSubmit(formState))
  }

  return (
    <PageWrapper>
      <Helmet title="Звіти" link={[{ rel: 'icon', type: 'image/png', href: reportIcon }]} />
      <ReportPageFilters onSubmit={handleSubmit} />
      {isLoading && <Skeleton rows={6} />}
      {data && (
        <ReportByB2B
          data={[
            ...data.items,
            {
              theme: 'Всього',
              subTheme: '-',
              category: '-',
              requestsCount: data.total.requestsCount,
            },
          ]}
          onDownload={handleDownload}
        />
      )}
    </PageWrapper>
  )
})
