import { makeStyles } from '@fluentui/react'

export const usePersonalAccountRequestPageStyles = makeStyles({
  container: {
    display: 'grid',
    gap: '16px',
    alignSelf: 'self-start',
    gridTemplateColumns: '880px 1fr',
    gridTemplateAreas: `
      'form details'
      'title title'
      'personalAccountDetails personalAccountDetails'
      'personalAccountConnectedAccounts personalAccountConnectedAccounts'
      'personalAccountHistory personalAccountHistory'
    `,
  },
  withFinance: {
    gridTemplateColumns: '726px 138px 1fr',
    gridTemplateAreas: `
      'form form details'
      'title title title'
      'personalAccountDetails personalAccountFinance personalAccountFinance'
      'personalAccountDetails personalAccountMeterReadings personalAccountMeterReadings'
      'personalAccountDetails . .'
      'personalAccountConnectedAccounts personalAccountConnectedAccounts personalAccountConnectedAccounts'
      'personalAccountHistory personalAccountHistory personalAccountHistory'
    `,
  },
  form: {
    gridArea: 'form',
  },
  details: {
    gridArea: 'details',
  },
  title: {
    gridArea: 'title',
  },
  personalAccountFinance: {
    gridArea: 'personalAccountFinance',
    alignSelf: 'self-start',
  },
  personalAccountDetails: {
    gridArea: 'personalAccountDetails',
  },
  personalAccountMeterReadings: {
    gridArea: 'personalAccountMeterReadings',
    alignSelf: 'self-start',
  },
  personalAccountConnectedAccounts: {
    gridArea: 'personalAccountConnectedAccounts',
  },
  personalAccountHistory: {
    gridArea: 'personalAccountHistory',
    backgroundColor: 'white !important',
  },
})
