import React from 'react'
import { useParams } from 'react-router-dom'

import { Helmet } from 'components/Helmet'
import { Box } from 'components/Box'
import { Skeleton } from 'components/Skeleton'
import { PageWrapper } from 'components/PageWrapper'
import { PersonalAccountHistory } from 'components/PersonalAccountListItem/PersonalAccountHistory'
import { ContactForm, ContactFormState } from 'components/ContactForm'
import {
  useGetContactQuery,
  useUpdateLegalEntityContactMutation,
  useUpdateNaturalPersonContactMutation,
} from 'store/contacts'
import { PersonType } from 'types'
import { mapContactToEdit } from 'utils/mappers/mapContactToEdit'
import accountIcon from 'assets/icons/account-icon.svg'
import { mapLegalEntityContactToSubmit, mapNaturalPersonContactToSubmit } from 'utils/mappers/mapContactToSubmit'
import { buildContactFullName } from 'utils/mappers/contactFullName'
import { isEntryDuplicateException } from 'utils/errors'
import { useGoBackOrClose } from 'hooks/useGoBackOrClose'

import { useContactPageStyles } from './styles'

export const ContactPage: React.FC = () => {
  const classes = useContactPageStyles()
  const { id } = useParams<{ id: string }>()
  const goBackOrClose = useGoBackOrClose()

  // --- queries ---
  const { data: contact } = useGetContactQuery(id!)

  // --- mutations ---
  const [updateLegalEntityContact] = useUpdateLegalEntityContactMutation()
  const [updateNaturalPersonContact] = useUpdateNaturalPersonContactMutation()

  // --- handlers ---
  const handleUpdateContact = async (formState: ContactFormState) => {
    try {
      if (formState.personType === PersonType.LEGAL_ENTITY) {
        await updateLegalEntityContact({ id: +id!, ...mapLegalEntityContactToSubmit(formState) }).unwrap()
      }

      if (formState.personType === PersonType.NATURAL_PERSON) {
        await updateNaturalPersonContact({ id: +id!, ...mapNaturalPersonContactToSubmit(formState) }).unwrap()
      }

      goBackOrClose()
    } catch (e) {
      if (isEntryDuplicateException(e)) {
        throw {
          phone: 'Номер вже додано',
        }
      }
      alert('Помилка при збереженні')
    }
  }

  const handleCancel = () => {
    goBackOrClose()
  }

  if (!contact) {
    return <Skeleton rows={6} />
  }

  return (
    <PageWrapper>
      <Helmet
        title={`Контакт: ${buildContactFullName(contact)}`}
        link={[{ rel: 'icon', type: 'image/png', href: accountIcon }]}
      />

      <Box className={classes.form}>
        <ContactForm
          onSubmit={handleUpdateContact}
          onCancel={handleCancel}
          defaultValues={mapContactToEdit(contact)}
          submitButtonText="Зберегти зміни і закрити"
        />
      </Box>
      <PersonalAccountHistory contactId={+id!} className={classes.history} scrollableHistory rounded limit={10} />
    </PageWrapper>
  )
}
