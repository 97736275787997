import { Option, OwnershipType } from 'types'

const ownershipTypeLabel: Record<OwnershipType, string> = {
  [OwnershipType.OWNER]: 'Власник',
  [OwnershipType.SECOND_PERSON]: 'Друга особа',
  [OwnershipType.THIRD_PERSON]: 'Третя особа',
  [OwnershipType.OTHER]: 'Інше',
}

export const getOwnershipTypeLabel = (ownershipType?: OwnershipType): string => {
  if (!ownershipType) return ''
  return ownershipTypeLabel[ownershipType]
}

export const ownershipTypeList = [
  OwnershipType.OWNER,
  OwnershipType.SECOND_PERSON,
  OwnershipType.THIRD_PERSON,
  OwnershipType.OTHER,
] as const

export const ownershipTypeOptions: Option<OwnershipType>[] = ownershipTypeList.map((key) => ({
  key,
  text: getOwnershipTypeLabel(key),
}))
