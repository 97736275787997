import React from 'react'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'

import { Helmet } from 'components/Helmet'
import { Skeleton } from 'components/Skeleton'
import { PageWrapper } from 'components/PageWrapper'
import { PersonalAccountDetails } from 'components/PersonalAccountDetails'
import { PersonalAccountFinance } from 'components/PersonalAccountFinance'
import { AccountDetailsFormState, AccountDetailsModal } from 'components/AccountDetailsModal'
import { NewReadingModal, NewReadingFormState } from 'components/NewReadingModal'
import { PersonalAccountMeterReadings } from 'components/PersonalAccountMeterReadings'
import { PersonalAccountHistory } from 'components/PersonalAccountListItem/PersonalAccountHistory'
import { PersonalAccountConnectedAccounts, type Account } from 'components/PersonalAccountConnectedPhones'
import {
  useGetPersonalAccountQuery,
  useGetPersonalAccountMeterReadingQuery,
  useGetPersonalAccountConnectedPhonesQuery,
  useCreatePersonalAccountMeterReadingMutation,
  useUpdatePersonalAccountConnectedPhoneMutation,
  useCreatePersonalAccountConnectedPhoneMutation,
  useGetPersonalAccountBalanceQuery,
} from 'store/personal-accounts'
import accountIcon from 'assets/icons/account-icon.svg'
import { mapAccountToEdit } from 'utils/mappers/mapAccountToEdit'

import { usePersonalAccountPageStyles } from './styles'
import { MeterType, PersonType, SupplyType } from 'types'
import { mapAccountToSubmitUpdate, mapAccountToSubmitCreate } from 'utils/mappers/mapAccountToSubmit'
import { isEntryDuplicateException } from 'utils/errors'

export const PersonalAccountPage = () => {
  const { id } = useParams()
  const classes = usePersonalAccountPageStyles()

  // --- state ---
  const [newReadingModalVisible, toggleNewReadingModalVisible] = React.useReducer((prev) => !prev, false)
  const [accountToEdit, setAccountToEdit] = React.useState<Account | null>(null)
  const [accountDetailsModalVisible, toggleAccountDetailsModalVisible] = React.useReducer((prev) => !prev, false)

  // --- queries ---
  const { data: balance } = useGetPersonalAccountBalanceQuery(id!)
  const { data: personalAccount } = useGetPersonalAccountQuery(id!)
  const { data: connectedPhones = [] } = useGetPersonalAccountConnectedPhonesQuery(id!)
  const { data: meterReading } = useGetPersonalAccountMeterReadingQuery(id!, { skip: true })

  // --- mutations ---
  const [createPersonalAccountMeterReading] = useCreatePersonalAccountMeterReadingMutation()
  const [updatePersonalAccountConnectedPhone] = useUpdatePersonalAccountConnectedPhoneMutation()
  const [createPersonalAccountConnectedPhone] = useCreatePersonalAccountConnectedPhoneMutation()

  // --- handlers ---
  const handleNewReadingModalSubmit = async (formState: NewReadingFormState) => {
    await createPersonalAccountMeterReading({ id: id!, ...formState })
    toggleNewReadingModalVisible()
  }

  const handlePersonalAccountConnectedAccountsEdit = (account: Account) => {
    setAccountToEdit(account)
    toggleAccountDetailsModalVisible()
  }

  const handleAccountDetailsModalSubmit = async (formValues: AccountDetailsFormState) => {
    try {
      if (accountToEdit) {
        await updatePersonalAccountConnectedPhone({
          id: id!,
          connectedPhoneId: accountToEdit.id,
          ...mapAccountToSubmitUpdate(formValues),
        }).unwrap()
      } else {
        await createPersonalAccountConnectedPhone({
          id: id!,
          ...mapAccountToSubmitCreate(formValues),
        }).unwrap()
      }

      toggleAccountDetailsModalVisible()
      setAccountToEdit(null)
    } catch (e) {
      if (isEntryDuplicateException(e)) {
        throw {
          phone: 'Номер вже додано',
        }
      }
      alert('Помилка при збереженні: ' + JSON.stringify(e))
    }
  }

  const handleAccountDetailsModalClose = () => {
    toggleAccountDetailsModalVisible()
    setAccountToEdit(null)
  }

  // --- computed ---
  const shouldShowFinanceBlock =
    personalAccount &&
    personalAccount.supplyType === SupplyType.ELECTRICITY &&
    personalAccount.personType === PersonType.NATURAL_PERSON

  if (!personalAccount || !balance) {
    return <Skeleton rows={6} />
  }

  return (
    <PageWrapper>
      <Helmet
        title={`О/Р: ${personalAccount.ownerName || personalAccount.number}`}
        link={[{ rel: 'icon', type: 'image/png', href: accountIcon }]}
      />

      <h1 className={classes.title}>Особовий рахунок №{personalAccount.number}</h1>
      <div className={clsx(classes.container, { [classes.withFinance]: shouldShowFinanceBlock })}>
        <PersonalAccountDetails className={classes.personalAccountDetails} personalAccount={personalAccount} />
        {shouldShowFinanceBlock && (
          <>
            <PersonalAccountFinance
              className={classes.personalAccountFinance}
              balance={balance.balance}
              accrualsForLastPeriod={balance.accrualForLastPeriod}
            />
            <PersonalAccountMeterReadings
              onAdd={toggleNewReadingModalVisible}
              className={classes.personalAccountMeterReadings}
              readings={meterReading?.readings || []}
            />
          </>
        )}
        <PersonalAccountConnectedAccounts
          onEdit={handlePersonalAccountConnectedAccountsEdit}
          onAdd={toggleAccountDetailsModalVisible}
          className={classes.personalAccountConnectedAccounts}
          accounts={connectedPhones}
        />
        <PersonalAccountHistory
          className={classes.personalAccountHistory}
          personalAccountId={+id!}
          scrollableHistory
          rounded
          limit={10}
        />
      </div>

      {/* modals */}
      <NewReadingModal
        visible={newReadingModalVisible}
        onClose={toggleNewReadingModalVisible}
        onSubmit={handleNewReadingModalSubmit}
        type={meterReading?.currentType ?? MeterType.ONE_ZONE}
      />
      <AccountDetailsModal
        visible={accountDetailsModalVisible}
        onClose={handleAccountDetailsModalClose}
        onSubmit={handleAccountDetailsModalSubmit}
        phoneEdit={!accountToEdit}
        defaultValues={accountToEdit ? mapAccountToEdit(accountToEdit) : undefined}
      />
    </PageWrapper>
  )
}
