import { sortBy } from 'utils/sortBy'
import { api } from '../api'
import { GetCompaniesDictionaryResponseDto } from './dtos/GetCompaniesDictionaryDto'
import type {
  GetCustomerServicesDictionaryRequestDto,
  GetCustomerServicesDictionaryResponseDto,
} from './dtos/GetCustomerServicesDictionaryDto'
import type {
  GetRequestsThemesDictionaryResponseDto,
  GetRequestsThemesDictionaryRequestDto,
} from './dtos/GetRequestsThemesDictionaryDto'
import type {
  GetRequestsSubThemesDictionaryResponseDto,
  GetRequestsSubThemesDictionaryRequestDto,
} from './dtos/GetRequestsSubThemesDictionaryDto'
import type {
  GetRequestsCategoryDictionaryResponseDto,
  GetRequestsCategoryDictionaryRequestDto,
} from './dtos/GetRequestsCategoryDictionaryDto'

export const dictionariesEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    getCompaniesDictionary: builder.query<GetCompaniesDictionaryResponseDto, void>({
      query: () => ({
        url: '/dictionaries/companies',
        method: 'GET',
      }),
    }),
    getCustomerServicesDictionary: builder.query<
      GetCustomerServicesDictionaryResponseDto,
      GetCustomerServicesDictionaryRequestDto
    >({
      query: (params) => ({
        url: '/dictionaries/customer-services',
        method: 'GET',
        params,
      }),
    }),
    getRequestsThemesDictionary: builder.query<
      GetRequestsThemesDictionaryResponseDto,
      GetRequestsThemesDictionaryRequestDto | void
    >({
      query: (params = {}) => ({
        url: '/dictionaries/requests-themes',
        method: 'GET',
        params,
      }),
      transformResponse: (response: GetRequestsThemesDictionaryResponseDto) => sortBy(response, 'order'),
    }),
    getRequestsSubThemesDictionary: builder.query<
      GetRequestsSubThemesDictionaryResponseDto,
      GetRequestsSubThemesDictionaryRequestDto
    >({
      query: ({ themeId }) => ({
        url: `/dictionaries/requests-themes/${themeId}/requests-sub-themes`,
        method: 'GET',
      }),
      transformResponse: (response: GetRequestsSubThemesDictionaryResponseDto) => sortBy(response, 'order'),
    }),
    getRequestsCategoryDictionary: builder.query<
      GetRequestsCategoryDictionaryResponseDto,
      GetRequestsCategoryDictionaryRequestDto
    >({
      query: ({ themeId, subThemeId }) => ({
        url: `/dictionaries/requests-themes/${themeId}/requests-sub-themes/${subThemeId}/requests-categories`,
        method: 'GET',
      }),
      transformResponse: (response: GetRequestsCategoryDictionaryResponseDto) => sortBy(response, 'order'),
    }),
  }),
})

export const {
  useGetCompaniesDictionaryQuery,
  useGetCustomerServicesDictionaryQuery,
  useGetRequestsThemesDictionaryQuery,
  useGetRequestsSubThemesDictionaryQuery,
  useGetRequestsCategoryDictionaryQuery,
} = dictionariesEndpoints
