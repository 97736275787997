import { makeStyles } from '@fluentui/react'
import { fonts } from 'styles/variables'

export const usePersonalAccountPageStyles = makeStyles({
  title: {
    ...fonts.title2,
  },
  container: {
    display: 'grid',
    gap: '16px',
    gridTemplateColumns: '1fr',
    gridTemplateAreas: `
      'personalAccountDetails'
      'personalAccountConnectedAccounts'
      'personalAccountHistory'
    `,
  },
  withFinance: {
    gridTemplateColumns: '726px 1fr',
    gridTemplateAreas: `
      'personalAccountDetails personalAccountFinance'
      'personalAccountDetails personalAccountMeterReadings'
      'personalAccountDetails .'
      'personalAccountConnectedAccounts personalAccountConnectedAccounts'
      'personalAccountHistory personalAccountHistory'
    `,
  },
  personalAccountDetails: {
    gridArea: 'personalAccountDetails',
  },
  personalAccountFinance: {
    gridArea: 'personalAccountFinance',
  },
  personalAccountMeterReadings: {
    gridArea: 'personalAccountMeterReadings',
  },
  personalAccountConnectedAccounts: {
    gridArea: 'personalAccountConnectedAccounts',
  },
  personalAccountHistory: {
    gridArea: 'personalAccountHistory',
    backgroundColor: 'white !important',
  },
})
