import React from 'react'
import ReactPaginate from 'react-paginate'

import { ReactComponent as NextIcon } from 'assets/icons/next-icon.svg'
import { ReactComponent as PrevIcon } from 'assets/icons/prev-icon.svg'

import { usePaginationStyles } from './styles'

interface PaginationProps {
  page: number
  pageCount: number
  onPageChange: (data: { selected: number }) => void
}

export const Pagination: React.FC<PaginationProps> = ({ page, onPageChange, pageCount }) => {
  const classes = usePaginationStyles()

  // if (pageCount <= 1) return null

  return (
    <ReactPaginate
      className={classes.pagination}
      nextClassName={classes.page}
      previousClassName={classes.page}
      pageClassName={classes.page}
      breakClassName={classes.break}
      activeClassName={classes.activePage}
      pageLinkClassName={classes.link}
      nextLinkClassName={classes.link}
      previousLinkClassName={classes.link}
      disabledClassName={classes.disabledLink}
      breakLabel="..."
      nextLabel={<NextIcon />}
      previousLabel={<PrevIcon />}
      onPageChange={onPageChange}
      pageRangeDisplayed={2}
      pageCount={pageCount}
      forcePage={page}
      renderOnZeroPageCount={null}
    />
  )
}
