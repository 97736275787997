import { ConnectedPhoneType } from 'types'

const connectedPhoneTypeLabels: Record<ConnectedPhoneType, string> = {
  [ConnectedPhoneType.MOBILE]: 'М.',
  [ConnectedPhoneType.HOME]: 'Д.',
  [ConnectedPhoneType.OTHER]: 'Р.',
}

export const getConnectedPhoneTypeLabel = (type?: ConnectedPhoneType) => {
  if (!type) return ''
  return connectedPhoneTypeLabels[type] ?? ''
}

export const connectedPhoneTypeList = [
  ConnectedPhoneType.MOBILE,
  ConnectedPhoneType.HOME,
  ConnectedPhoneType.OTHER,
] as const

export const connectedPhoneTypeOptions = connectedPhoneTypeList.map((key) => ({
  key,
  text: getConnectedPhoneTypeLabel(key),
}))
