import { makeStyles } from '@fluentui/react'
import { colors, fonts, space } from 'styles/variables'

export const useMainPageStyles = makeStyles({
  title: {
    ...fonts.title3,
    color: colors.grey14,
    marginTop: '0px',
    marginBottom: space.md,
  },
})
