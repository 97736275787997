import { api } from '../api'
import type { GetEscalationResponseDto, GetEscalationRequestDto } from './dtos/GetEscalationDto'

const escalationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEscalation: builder.query<GetEscalationResponseDto, GetEscalationRequestDto>({
      query: (params) => ({ url: '/escalation', params, method: 'GET' }),
    }),
  }),
})

export const { useGetEscalationQuery } = escalationApi
