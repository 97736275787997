import { Pivot, PivotItem } from '@fluentui/react'
import { Option } from 'types'

interface TabsProps<T extends string> {
  value: T
  onChange: (nextValue: T) => void
  options: Option<T>[]
  className?: string
}

export const Tabs = <T extends string>({ value, onChange, options, className }: TabsProps<T>) => {
  return (
    <Pivot className={className} selectedKey={value} onLinkClick={(e) => onChange(e?.props.itemKey as T)}>
      {options.map((option) => (
        <PivotItem key={option.key} itemKey={option.key} headerText={option.text} />
      ))}
    </Pivot>
  )
}
