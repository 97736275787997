import {
  Address,
  CompanyType,
  DateTimeString,
  Long,
  Pagination,
  PersonType,
  PersonalAccountNumber,
  RequestPaginationDto,
  SupplyType,
} from 'types'

export interface GetPersonalAccountsListRequestDto extends RequestPaginationDto {
  number?: string
  fullName?: string
  address?: string
  phoneNumber?: string
  personType?: PersonType
  company?: CompanyType
  requestNumber?: string
  customerServiceCentreId?: number
}

export enum PersonalAccountsListItemIdentifierType {
  PHONE = 'PHONE',
  PERSONAL_ACCOUNT = 'PERSONAL_ACCOUNT',
}

export interface PersonalAccountsListItem {
  id: Long
  identifierType: PersonalAccountsListItemIdentifierType.PERSONAL_ACCOUNT
  personType: PersonType
  fullName: string
  lastRequestDate?: DateTimeString
  requestsCount: number
  supplyType: SupplyType
  number: PersonalAccountNumber
  address: Address
  hasBlockedPhone?: boolean
}

export interface ContactListItem {
  id: Long
  identifierType: PersonalAccountsListItemIdentifierType.PHONE
  personType: PersonType
  fullName: string
  lastRequestDate?: DateTimeString
  requestsCount: number
  hasBlockedPhone?: boolean
}

export type GetPersonalAccountsListResponseDto = Pagination<PersonalAccountsListItem | ContactListItem>
