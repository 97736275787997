import { LegalEntityContact, NaturalPersonContact } from 'store/contacts/dtos/GetContactDto'
import { PersonType } from 'types'
import { buildFullName } from 'utils/fullName'

export const buildContactFullName = (contact: NaturalPersonContact | LegalEntityContact) => {
  return buildFullName(
    contact.type === PersonType.LEGAL_ENTITY
      ? {
          firstName: contact.companyRepresentativeFirstName,
          lastName: contact.companyRepresentativeLastName,
          middleName: contact.companyRepresentativeMiddleName,
        }
      : contact
  )
}
