import { SupplyType, Option } from 'types'

const supplyTypeLabel: Record<SupplyType, string> = {
  [SupplyType.ELECTRICITY]: 'Електроенергія',
  [SupplyType.NATURAL_GAS]: 'Природний газ',
}

export const getSupplyTypeLabel = (supplyType?: SupplyType): string => {
  if (!supplyType) return ''
  return supplyTypeLabel[supplyType]
}

export const supplyTypeList = [SupplyType.ELECTRICITY, SupplyType.NATURAL_GAS] as const

export const supplyTypeOptions: Option<SupplyType>[] = supplyTypeList.map((key) => ({
  key,
  text: getSupplyTypeLabel(key),
}))
